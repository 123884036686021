@charset "utf-8";
/* ---------------------------------------------------------
   util.css
--------------------------------------------------------- */

/* layout */
.none {
  display: none !important;
}
.block {
  display: block !important;
}
.inline {
  display: inline !important;
}
.f_left {
  float: left  !important ;
}
.f_right {
  float: right  !important ;
}
.left {
  text-align: left !important;
}
.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}
.z0 {
  position: relative !important;
  z-index: 0 !important;
}

/* Micro clearfix */
.clearfix {
 *zoom: 1 ;
}
.clearfix:before , .clearfix:after {
  content: " " ;
  display: table ;
}
.clearfix:after {
  clear: both ;
}
.clear {
  clear: both ;
  overflow: hidden ;
  border: 0 ;
  padding: 0 ;
  font-size: 0 ;
  line-height: 0 ;
}

/* font */
.bold {
  font-weight: bold !important;
}
.no_bold {
  font-weight: normal !important;
}
/* fontsize */
.f10 {
  font-size: 10px !important;
  font-size: 1rem !important;
}

.f11 {
  font-size: 11px !important;
  font-size: 1.1rem !important;
}

.f12 {
  font-size: 12px !important;
  font-size: 1.2rem !important;
}

.f13 {
  font-size: 13px !important;
  font-size: 1.3rem !important;
}

.f14 {
  font-size: 14px !important;
  font-size: 1.4rem !important;
}

.f15 {
  font-size: 15px !important;
  font-size: 1.5rem !important;
}

.f16 {
  font-size: 16px !important;
  font-size: 1.6rem !important;
}

.f17 {
  font-size: 17px !important;
  font-size: 1.7rem !important;
}

.f18 {
  font-size: 18px !important;
  font-size: 1.8rem !important;
}

.f19 {
  font-size: 19px !important;
  font-size: 1.9rem !important;
}

.f20 {
  font-size: 20px !important;
  font-size: 2rem !important;
}

.f21 {
  font-size: 21px !important;
  font-size: 2.1rem !important;
}

.f22 {
  font-size: 22px !important;
  font-size: 2.2rem !important;
}

.f23 {
  font-size: 23px !important;
  font-size: 2.3rem !important;
}

.f24 {
  font-size: 24px !important;
  font-size: 2.4rem !important;
}

.f25 {
  font-size: 25px !important;
  font-size: 2.5rem !important;
}

.f26 {
  font-size: 26px !important;
  font-size: 2.6rem !important;
}

.f27 {
  font-size: 27px !important;
  font-size: 2.7rem !important;
}

.f28 {
  font-size: 28px !important;
  font-size: 2.8rem !important;
}

.f29 {
  font-size: 29px !important;
  font-size: 2.9rem !important;
}

.f30 {
  font-size: 30px !important;
  font-size: 3rem !important;
}

.f31 {
  font-size: 31px !important;
  font-size: 3.1rem !important;
}

.f32 {
  font-size: 32px !important;
  font-size: 3.2rem !important;
}

.f33 {
  font-size: 33px !important;
  font-size: 3.3rem !important;
}

.f34 {
  font-size: 34px !important;
  font-size: 3.4rem !important;
}

.f35 {
  font-size: 35px !important;
  font-size: 3.5rem !important;
}

.f36 {
  font-size: 36px !important;
  font-size: 3.6rem !important;
}

.f37 {
  font-size: 37px !important;
  font-size: 3.7rem !important;
}

.f38 {
  font-size: 38px !important;
  font-size: 3.8rem !important;
}

.f39 {
  font-size: 39px !important;
  font-size: 3.9rem !important;
}

.f40 {
  font-size: 40px !important;
  font-size: 4rem !important;
}

/* line-height */
.lh10 { line-height: 10px !important;}
.lh11 { line-height: 11px !important;}
.lh12 { line-height: 12px !important;}
.lh13 { line-height: 13px !important;}
.lh14 { line-height: 14px !important;}
.lh15 { line-height: 15px !important;}
.lh16 { line-height: 16px !important;}
.lh17 { line-height: 17px !important;}
.lh18 { line-height: 18px !important;}
.lh19 { line-height: 19px !important;}
.lh20 { line-height: 20px !important;}
.lh21 { line-height: 21px !important;}
.lh22 { line-height: 22px !important;}
.lh23 { line-height: 23px !important;}
.lh24 { line-height: 24px !important;}
.lh25 { line-height: 25px !important;}
.lh26 { line-height: 26px !important;}
.lh27 { line-height: 27px !important;}
.lh28 { line-height: 28px !important;}
.lh29 { line-height: 29px !important;}
.lh30 { line-height: 30px !important;}
.lh31 { line-height: 31px !important;}
.lh32 { line-height: 32px !important;}
.lh33 { line-height: 33px !important;}
.lh34 { line-height: 34px !important;}
.lh35 { line-height: 35px !important;}
.lh36 { line-height: 36px !important;}
.lh37 { line-height: 37px !important;}
.lh38 { line-height: 38px !important;}
.lh39 { line-height: 39px !important;}
.lh40 { line-height: 40px !important;}

/* Margin-Top & Padding-Top */
.mt00 { margin-top:  0px !important; }    .pt00 { padding-top:  0px !important; }
.mt05 { margin-top:  5px !important; }    .pt05 { padding-top:  5px !important; }
.mt10 { margin-top: 10px !important; }    .pt10 { padding-top: 10px !important; }
.mt15 { margin-top: 15px !important; }    .pt15 { padding-top: 15px !important; }
.mt20 { margin-top: 20px !important; }    .pt20 { padding-top: 20px !important; }
.mt25 { margin-top: 25px !important; }    .pt25 { padding-top: 25px !important; }
.mt30 { margin-top: 30px !important; }    .pt30 { padding-top: 30px !important; }
.mt35 { margin-top: 35px !important; }    .pt35 { padding-top: 35px !important; }
.mt40 { margin-top: 40px !important; }    .pt40 { padding-top: 40px !important; }
.mt45 { margin-top: 45px !important; }    .pt45 { padding-top: 45px !important; }
.mt50 { margin-top: 50px !important; }    .pt50 { padding-top: 50px !important; }
.mt55 { margin-top: 55px !important; }    .pt55 { padding-top: 55px !important; }
.mt60 { margin-top: 60px !important; }    .pt60 { padding-top: 60px !important; }
.mt65 { margin-top: 65px !important; }    .pt65 { padding-top: 65px !important; }
.mt70 { margin-top: 70px !important; }    .pt70 { padding-top: 70px !important; }
.mt75 { margin-top: 75px !important; }    .pt75 { padding-top: 75px !important; }
.mt80 { margin-top: 80px !important; }    .pt80 { padding-top: 80px !important; }
.mt85 { margin-top: 85px !important; }    .pt85 { padding-top: 85px !important; }
.mt90 { margin-top: 90px !important; }    .pt90 { padding-top: 90px !important; }
.mt95 { margin-top: 95px !important; }    .pt95 { padding-top: 95px !important; }
.mt100{ margin-top:100px !important; }    .pt100{ padding-top:100px !important; }

/* Margin-Right & Padding-Right */
.mr00 { margin-right:  0px !important; }  .pr00 { padding-right:  0px !important; }
.mr05 { margin-right:  5px !important; }  .pr05 { padding-right:  5px !important; }
.mr10 { margin-right: 10px !important; }  .pr10 { padding-right: 10px !important; }
.mr15 { margin-right: 15px !important; }  .pr15 { padding-right: 15px !important; }
.mr20 { margin-right: 20px !important; }  .pr20 { padding-right: 20px !important; }
.mr25 { margin-right: 25px !important; }  .pr25 { padding-right: 25px !important; }
.mr30 { margin-right: 30px !important; }  .pr30 { padding-right: 30px !important; }
.mr35 { margin-right: 35px !important; }  .pr35 { padding-right: 35px !important; }
.mr40 { margin-right: 40px !important; }  .pr40 { padding-right: 40px !important; }
.mr45 { margin-right: 45px !important; }  .pr45 { padding-right: 45px !important; }
.mr50 { margin-right: 50px !important; }  .pr50 { padding-right: 50px !important; }
.mr55 { margin-right: 55px !important; }  .pr55 { padding-right: 55px !important; }
.mr60 { margin-right: 60px !important; }  .pr60 { padding-right: 60px !important; }
.mr65 { margin-right: 65px !important; }  .pr65 { padding-right: 65px !important; }
.mr70 { margin-right: 70px !important; }  .pr70 { padding-right: 70px !important; }
.mr75 { margin-right: 75px !important; }  .pr75 { padding-right: 75px !important; }
.mr80 { margin-right: 80px !important; }  .pr80 { padding-right: 80px !important; }
.mr85 { margin-right: 85px !important; }  .pr85 { padding-right: 85px !important; }
.mr90 { margin-right: 90px !important; }  .pr90 { padding-right: 90px !important; }
.mr95 { margin-right: 95px !important; }  .pt95 { padding-right: 95px !important; }
.mr100{ margin-right:100px !important; }  .pr100{ padding-right:100px !important; }

/* Margin-Bottom & Padding-Bottom */
.mb00 { margin-bottom:  0px !important; } .pb00 { padding-bottom:  0px !important; }
.mb05 { margin-bottom:  5px !important; } .pb05 { padding-bottom:  5px !important; }
.mb10 { margin-bottom: 10px !important; } .pb10 { padding-bottom: 10px !important; }
.mb15 { margin-bottom: 15px !important; } .pb15 { padding-bottom: 15px !important; }
.mb20 { margin-bottom: 20px !important; } .pb20 { padding-bottom: 20px !important; }
.mb25 { margin-bottom: 25px !important; } .pb25 { padding-bottom: 25px !important; }
.mb30 { margin-bottom: 30px !important; } .pb30 { padding-bottom: 30px !important; }
.mb35 { margin-bottom: 35px !important; } .pb35 { padding-bottom: 35px !important; }
.mb40 { margin-bottom: 40px !important; } .pb40 { padding-bottom: 40px !important; }
.mb45 { margin-bottom: 45px !important; } .pb45 { padding-bottom: 45px !important; }
.mb50 { margin-bottom: 50px !important; } .pb50 { padding-bottom: 50px !important; }
.mb55 { margin-bottom: 55px !important; } .pb55 { padding-bottom: 55px !important; }
.mb60 { margin-bottom: 60px !important; } .pb60 { padding-bottom: 60px !important; }
.mb65 { margin-bottom: 65px !important; } .pb65 { padding-bottom: 65px !important; }
.mb70 { margin-bottom: 70px !important; } .pb70 { padding-bottom: 70px !important; }
.mb75 { margin-bottom: 75px !important; } .pb75 { padding-bottom: 75px !important; }
.mb80 { margin-bottom: 80px !important; } .pb80 { padding-bottom: 80px !important; }
.mb85 { margin-bottom: 85px !important; } .pb85 { padding-bottom: 85px !important; }
.mb90 { margin-bottom: 90px !important; } .pb90 { padding-bottom: 90px !important; }
.mb95 { margin-bottom: 95px !important; } .pt95 { padding-bottom: 95px !important; }
.mb100{ margin-bottom:100px !important; } .pb100{ padding-bottom:100px !important; }

/* Margin-Left & Padding-Left */
.ml00 { margin-left:  0px !important; }   .pl00 { padding-left:  0px !important; }
.ml05 { margin-left:  5px !important; }   .pl05 { padding-left:  5px !important; }
.ml10 { margin-left: 10px !important; }   .pl10 { padding-left: 10px !important; }
.ml15 { margin-left: 15px !important; }   .pl15 { padding-left: 15px !important; }
.ml20 { margin-left: 20px !important; }   .pl20 { padding-left: 20px !important; }
.ml25 { margin-left: 25px !important; }   .pl25 { padding-left: 25px !important; }
.ml30 { margin-left: 30px !important; }   .pl30 { padding-left: 30px !important; }
.ml35 { margin-left: 35px !important; }   .pl35 { padding-left: 35px !important; }
.ml40 { margin-left: 40px !important; }   .pl40 { padding-left: 40px !important; }
.ml45 { margin-left: 45px !important; }   .pl45 { padding-left: 45px !important; }
.ml50 { margin-left: 50px !important; }   .pl50 { padding-left: 50px !important; }
.ml55 { margin-left: 55px !important; }   .pl55 { padding-left: 55px !important; }
.ml60 { margin-left: 60px !important; }   .pl60 { padding-left: 60px !important; }
.ml65 { margin-left: 65px !important; }   .pl65 { padding-left: 65px !important; }
.ml70 { margin-left: 70px !important; }   .pl70 { padding-left: 70px !important; }
.ml75 { margin-left: 75px !important; }   .pl75 { padding-left: 75px !important; }
.ml80 { margin-left: 80px !important; }   .pl80 { padding-left: 80px !important; }
.ml85 { margin-left: 85px !important; }   .pb85 { padding-left: 85px !important; }
.ml90 { margin-left: 90px !important; }   .pl90 { padding-left: 90px !important; }
.ml95 { margin-left: 95px !important; }   .pt95 { padding-left: 95px !important; }
.ml100{ margin-left:100px !important; }   .pl100{ padding-left:100px !important; }

/* ---------------------------------------------------------
   add
----------------------------------------------------------*/
 /* 古いIEへ警告 */
  .ie-prompt{
    text-align: center;
    margin: 0px auto 0px;
    padding: 10px;
    background-color:#ccc;
    color: #000;
  }
  .ie-prompt a{color: #000;}

/* 音声ブラウザ用 */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

/* プリント表示非表示 */
    @media print {
      .is-hidden_print{
        display: none;
      }
      .is-visible_print{
        display: block;
      }
    }

/* over */
.opacity:hover {
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  opacity: 0.7;
}
.opacity:hover img {
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  opacity: 0.7;
}