@charset "utf-8";
/* ---------------------------------------------------------
   page.css
----------------------------------------------------------*/
.horizontal_scroll {
  display: flex;
  margin: 20px 0 30px;
  @media #{$scr_sp} {
    overflow-x: scroll;
    overflow-scrolling: touch;
    // margin: 40px 0;
    margin: 20px 0 40px 5%;
  }
  li {
    @media #{$scr_pc} {
      width: 270px;
    }
    @media #{$scr_sp} {
      flex: 0 0 40%;
    }
    &:not(:last-child) {
      margin-right: 40px;
       @media #{$scr_sp} {
        margin-right: 10px;
      }
    }
    &:last-child {
      @media #{$scr_sp} {
        margin-right: 5%;
      }
    }
    img {
      width: 100%;
      @media #{$scr_sp} {
      }
    }
  }
  a {
    color: $color_text;
    text-decoration: none;
    &:hover {
      p {
        text-decoration: underline;
      }
    }
  }
  p {
    margin-top: 10px;
  }
}
.attention-for-reaction_btn {
  // border: 3px solid #f00;
  background: #fff;
  padding: 15px;
  margin-bottom: 80px;
  position: relative;
  img {
    display: none;
  }
  p {
    text-align: center;
    @include font-size(1.8);
    span {
      color: #f00;
    }
  }
  @media #{$scr_sp} {
    margin: 0 5% 20%;
    img {
      display: block;
    }
    p {
      @include font-size(1.6);
      text-align: left;
      span {
        display: none;
      }
    }
  }
}

/* ---------------------------------------------------------
   top
----------------------------------------------------------*/
.top {
  .opening_message {
    text-align: center;
    a {
      text-decoration: none;
      display: block;
      background: #fff;
      margin: 0 0 65px 0 ;
      padding: 20px;
      border:2px solid $color_light_blue;
      transition: all .5s ease ;
      box-sizing:border-box;
      @media #{$scr_sp} {
        margin-left: 5%;
        margin-right: 5%;
      }
      &:hover {
        border:6px solid $color_light_blue;
        padding: 16px;
      }
      span {
        display: block;
      }
      .big {
        @include font-size(1.8);
        font-weight: bold;
      }
      .small {
        @include font-size(1.4);
      }
      .newwindow {
        fill:$color_light_blue;;
        margin-left: 5px;
        width: 12px;
      }
    }
  }
  .slide {
    text-align: center;
    margin-bottom: 65px;
    @media #{$scr_sp} {
      margin-left: 5%;
      margin-right: 5%;
      margin-bottom: 30px;
    }

  }
  .slide_main {
    margin-bottom: 5px;
    display: none;
  }
  .block_main {
    margin-bottom: 80px;
  }
  .sp-mask {
    //overflow: visible !important;
  }
  .sp-thumbnail-arrow{
    // background: $color_main;
    // width: 16px;
    // height: 70px;
    &:before , &:after {
      // display: none;
    }
  }
  .sp-selected-thumbnail {
    opacity: 0.5;
  }
  .list_community {
    margin-bottom: 80px;
    ul {
      display: flex;
      flex-wrap:wrap;
      justify-content:flex-start;
      li {
        margin: 0 2px 2px 0;
        @media #{$scr_sp} {
          //flex:1;
          width: calc(50% - 2px);
        }
        &:nth-child(5n+5) {
          margin-right: 0;
          @media #{$scr_sp} {
            margin: 0 2px 2px 0;
          }
        }
        &:nth-child(2n+2) {
          @media #{$scr_sp} {
            margin-right: 0;
          }
        }
        &.goods {
          .title {
            @include font-size(1.2);
            margin-bottom: 6px;
          }
        }
        &.raise {
          .title {
            @include font-size(1.2);
            margin-bottom: 6px;
          }
        }
        &.watching {
          .title {
            @include font-size(1.2);
            margin-bottom: 6px;
          }
        }
        &.zatsudan {
          a {
            &:hover {
            background: #3ed7ea;
           }
          }
        }
        &.kaikata {
          a {
            &:hover {
            background: #4dbaee;
           }
          }
        }
        &.kenkou {
          a {
            &:hover {
            background: #45a8ff;
           }
          }
        }
        &.byouki {
          a {
            &:hover {
            background: #1c98f9;
           }
          }
        }
        &.jiman {
          a {
            &:hover {
            background: #c985ff;
           }
          }
        }
        &.off {
          a {
            &:hover {
            background: #f282ff;
           }
          }
        }
        &.goods {
          a {
            &:hover {
            background: #fe678c;
           }
          }
        }
        &.hanbai {
          a {
            &:hover {
            background: #fe4f4f;
           }
          }
        }
        &.raise {
          a {
            &:hover {
            background: #ff6d33;
           }
          }
        }
        &.loss {
          a {
            &:hover {
            background: #ff961b;
           }
          }
        }
        &.watching {
          a {
            &:hover {
            background: #f6d915;
           }
          }
        }
        &.maigo {
          a {
            &:hover {
            background: #a2ed00;
           }
          }
        }
        &.hogo {
          a {
            &:hover {
            background: #33ea74;
           }
          }
        }
        &.satooya {
          a {
            &:hover {
            background: #2dead2;
           }
          }
        }
      }
      a {
        position: relative;
        display: block;
        width: 170px;
        padding: 10px;
        background: #fff;
        box-sizing:border-box;
        color: $color_text;
        text-decoration: none;
        text-align: center;
        @media #{$scr_sp} {
          width: 100%;
          height: 100%;
        }
        &:hover {
          .math , .title {
            color: #fff;
          }
          svg {
            fill:#fff;
          }
        }
      }
      .title {
        margin-top: 10px;
      }
      .math {
        @include font-size(1.2);
        color: $color_text-light;
      }
      svg {
        width: 30px;
        height: 30px;
        margin: 8px 0;
      }
      .hane {
        fill:#3ed7ea;
      }
      .parrot {
        fill:#4dbaee;
      }
      .fork {
        fill:#45a8ff;
      }
      .hospital {
        fill:#1c98f9;
      }
      .hart {
        fill:#c985ff;
      }
      .cracker {
        fill:#f282ff;
      }
      .stump {
        fill:#fe678c;
      }
      .shop {
        fill:#fe4f4f;
      }
      .nest {
        fill:#ff6d33;
      }
      .water {
        fill:#ff961b;
      }
      .binoculars {
        fill:#f6d915;
      }
      .chick {
        fill:#a2ed00;
      }
      .love {
        fill:#33ea74;
      }
      .kago {
        fill:#2dead2;
      }
    }
  }
  .more_topic {
    display: block;
    text-align: center;
    background: #fff;
    padding: 15px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    .arrow {
      fill:$color_main;
      margin-right: 5px;
      width: 10px;
      height: 10px;
      vertical-align: 1px;
    }
  }
  .lost_inner {
    display: flex;
    justify-content:space-between;
    margin-bottom: 20px;
    @media #{$scr_sp} {
      display: block;
    }
    section {
      @media #{$scr_sp} {
        margin-bottom: 40px;
      }
    }
    .maigo {
      margin-right: 20px;
      width: 100%;
    }
    .hogo {
      width: 100%;
    }
  }
  .title_lost {
    position: relative;
    margin: 0;
    margin-bottom: 2px;
    padding: 20px;
    background: #fff;
    @include font-size(1.8);
    &:before {
      content: "探しています";
      position: absolute;
      left: -20px;
      top:-20px;
      padding: 5px 10px;
      @include font-size(1.4);
      background: $color_main;
      color: #fff;
    }
    &:after {
      content: "";
      position: absolute;
      left: 15px;
      top:5px;
      width: 0;
      height: 0;
      border-top: 15px solid $color_main;
      border-right: 5px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 5px solid transparent;
      transform:rotate(-30deg);
    }
    .more {
      position: absolute;
      right: 20px;
      top:25px;
      @include font-size(1.2);
      color: $color_text;
      font-weight: normal;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      .arrow {
        margin-right: 5px;
      }
    }
    &.title_hogo {
      &:before {
        content: "保護しました";
      }
    }
  }
  .list_lost {
    li {
      margin-bottom: 2px;
    }
    a {
      position: relative;
      display: block;
      background: #fff;
      padding: 20px;
      text-decoration: none;
      &:hover {
        .title {
          color:$color_main;
        }
      }
    }
    time {
      color: $color_text-light;
      @include font-size(1.4);
      margin-right: 15px;
    }
    .title {
      margin: 0;
      @include font-size(1.6);
      color: $color_text;
      font-weight: normal;
    }
  }
  .lost_sns {
    padding: 40px;
    text-align: center;
    .copy {
      font-weight: bold;
      margin-bottom: 20px;
      @media #{$scr_sp} {
        @include font-size(1.4) ;
        margin-bottom: 10px;
      }
      .strong {
        color: #111;
        @include font-size(2.4) ;
        @media #{$scr_sp} {
          @include font-size(2.0) ;
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
    }
    .list_btn {
      display: flex;
      justify-content:space-between;
      @media #{$scr_sp} {
        display: block;
      }
      li {
        width: 100%;
        &:first-child {
          margin-right: 15px;
          @media #{$scr_sp} {
            margin-bottom: 10px;
          }
        }
      }
      a {
        display: block;
        padding: 15px;
        background: $color_twitter;
        color: #fff;
        @include font-size(1.8) ;
        text-decoration: none;
        @media #{$scr_sp} {
          @include font-size(1.4) ;
        }
        svg {
          margin-left: 10px;
          fill:#fff;
           @media #{$scr_sp} {
            width: 14px;
            height: 14px;
           }
        }
        &.btn_facebook {
          background: $color_facebook;
        }
      }
    }
  }
}
/* ---------------------------------------------------------
   とりっちとは？
----------------------------------------------------------*/
.about {
  .main {
    @media #{$scr_pc} {
      width: 100%;
    }
    float: none;
    box-sizing:borer-box;
    p {
      text-align: center;
    }
    .lower_visual {
      img {
        width: 100%;
      }
    }
    .copy {
      @include font-size(3.0);
      font-weight: bold;
      color:$color_main;
      margin: 60px 0;
    }
    .title_sub {
      margin: 120px 0 60px;
      text-align: center;
      span {
        display: inline-block;
        position: relative;
        text-align: center;
        @include font-size(2.4);
        &:before , &:after {
          position: absolute;
          top:16px;
          left: -36px;
          content: "";
          width: 26px;
          height: 1px;
          background: $color_text;
        }
        &:after {
          left: auto;
          right: -46px;
        }
      }
    }
    .list_tokuchou {
      text-align: center;
      @media #{$scr_pc} {
        width: 800px;
        margin: 0 auto 60px;
      }
      @media #{$scr_sp} {
        display: flex;
        flex-wrap:wrap;
        justify-content:space-between;
      }
      li {
        display: inline-block;
        margin: 0 30px 60px;
        @media #{$scr_sp} {
          margin: 0;
          margin-bottom: 60px;
          width: 50%;
        }
        &:nth-child(odd) {
          svg {
            fill:$color_main;
          }
        }
        &:nth-child(even) {
          svg {
            fill:#fe86a4;
          }
        }
        p {
          margin-bottom: 20px;
        }
      }
      .svg-icon {
        width: 60px;
        height: 60px;
      }
    }
  }
}
/* ---------------------------------------------------------
   メンバーリスト
----------------------------------------------------------*/
.usrsearch {
  .main {
    .title_lvl01 {
      margin-bottom: 2em;
    }
    .title_lvl02 {
      &:first-of-type {
        margin-top: 0;
      }
    }
    #bbs_bird {
      margin-bottom: 0;
    }
    dt {
      @media #{$scr_pc} {
        width: calc(25% - 2px);
      }
    }
    dd {
      @media #{$scr_pc} {
        width: 75%;
      }
    }
  }
}
/* ---------------------------------------------------------
   メンバー詳細
----------------------------------------------------------*/
.profile {
  .main {
    .list_btn {
      display: flex;
      justify-content:flex-end;
      @media #{$scr_sp} {
        display: block;
      }
      li {
        margin-left: 20px;
        @include font-size(1.4);
        @media #{$scr_sp} {
          margin-left: 0;
        }
        button {
          background: $color_sub;
          @media #{$scr_sp} {
            width: 100%;
            margin-bottom: 20px;
          }
          .fa {
            margin-right: 5px;
          }
        }
      }
    }
    .list_default {
      @include font-size(1.4);
    }
  }
}

/* ---------------------------------------------------------
   鳥図鑑
----------------------------------------------------------*/
.birds {
  .main {
    .title_blue {
      margin-bottom: 0;
    }
    .title_lvl03 {
      margin-bottom: 10px;
    }
    .box_zukan {
      display: flex;
      justify-content:space-between;
      margin-bottom: 60px;
      @media #{$scr_sp} {
        display: block;
      }
      .text {
        margin-bottom: 0;
        display: block;
        &:before{
          display: none;
        }
        .name{
          color: $color_main;
        }
        .kainushi {
          font-weight: normal;
          color: $color_text !important;
          @include font-size(1.2);
        }
        .caption {
          color: $color_sub !important;
        }
        .access {
          @include font-size(1.2);
          color: $color_text-light
        }
      }
    }
    .box_inner {
      width: 415px;
      margin-bottom: 0;
      @media #{$scr_sp} {
        width: 100%;
      }
    }
    .box_ranking {
      @media #{$scr_sp} {
        margin-bottom: 40px;
      }
      & > ol {
        list-style: none;
        & > li {
          margin-top: 2px;
        }
        a {
          position: relative;
          display: block;
          background: #fff;
          padding: 15px;
          @include clearfix;
          &:hover {
            text-decoration: none;
          }
        }
        figure {
          width: 100px;
          height: 100px;
          overflow: hidden;
          float: left;
          img {
            width: 100%;

          }
        }
        .caption {
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .text {
          padding-left: 115px;
        }
      }
      p.caution {
        text-align: right;
        font-size: 0.8em;
        margin: 0;
      }
    }
    .box_pickup {
      .pickup_inner {
        margin-top: 2px;
        margin-bottom: 0;
        a {
          &:hover {
            text-decoration: none;
          }
        }
        .pickup_img {
          background: #fff;
          text-align: center;
          padding: 50px;
          margin-bottom: 0;
          figure {
            width: 315px;
            height: 315px;
            overflow: hidden;
            @media #{$scr_sp} {
              width: auto;
              height: auto;
            }
          }
          img {
            width: 100%;
          }
        }
        .caption {
          text-indent: 0;
        }
        .text {
          height: 240px;
          margin-top: 2px;
          padding: 50px;
          background: #fff;
          box-sizing:border-box;
          @media #{$scr_sp} {
            height: auto;
          }
        }
      }
    }
    .list_topic {
      margin-top: 2px;
      margin-bottom: 2px;
      p {
        margin-bottom: 0;
      }
      .title {
        margin-bottom: 0;
      }
    }
    .pageng {
      margin-bottom: 60px;
    }
    .list_member {
      margin-top: 10px;
    }
  }
  .bird_text {
    p,h3 {
      margin-bottom: 5px;
    }
  }
}
/* ---------------------------------------------------------
   鳥病院ガイド
----------------------------------------------------------*/
.hospital {
  .main {
    .title_lvl03 {
      margin-bottom: 10px;
    }
    .list_map {
      display: flex;
      flex-wrap:wrap;
      li {
        margin-right: 10px;
        margin-bottom: 10px;
      }
      a {
        display: block;
        background: #fff;
        padding: 15px 30px;
      }
    }
    .table_default {
      .col01 {
        background: #fff;
      }
    }
  }
}
/* ---------------------------------------------------------
   メンバー会員登録・ログイン
----------------------------------------------------------*/
.register , .user_login{
  .main {
    .dl_default {
      dt {
        @media #{$scr_pc} {
          width: calc(25% - 2px);
        }
      }
      dd {
        @media #{$scr_pc} {
          width: 75%;
        }
      }
    }
    .btn_submit {
      @media #{$scr_pc} {
        width: 300px;
      }
    }
    #mail {
      min-width: 240px;
    }
    .boutou {
      margin-top: 2em;
    }
  }
}
/* ---------------------------------------------------------
   ログイン
----------------------------------------------------------*/
.user_login {
  .main {
  }
}
/* ---------------------------------------------------------
   マイページ
----------------------------------------------------------*/
.mypage {
  .main {
    .block {
      .title_lvl02 {
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .subnav_tab_wrapper {
      margin-top: 0;
    }
    .subnav_tab {
      @media #{$scr_sp} {
        margin-top: 30px;
      }
    }
    .title_lvl01 {
      margin-top: 2em;
      margin-bottom: 1.4em;
    }
    .title_blue {
      margin: 0;
    }
    .btn {
      //@include font-size(1.2);
    }
    #announce {
      padding: 10px 10px;
      border: 2px solid #aaa;
      margin-bottom: 20px;
      border-radius: 10px;
      p {
        margin-bottom: 5px;
      }
      p.strong {
        text-align: center;
      }
    }
    .profile {
      .title_lvl02 {
        margin-top: 0;
      }
      .profile_header {
        margin-bottom: 23px;
        display: flex;
        .img {
          margin-right:30px;
        }
        figcaption {
          @include font-size(1.2);
        }
        .text {
          color: $color_text;
          &:before{
            display: none;
          }
          .title {
            @include font-size(2.2);
            margin:0 0 10px;
          }
          p{
            @include font-size(1.6);
            font-weight: normal;
          }
        }
      }
    }
    .lovely {

    }
    .keijiban {
      textarea {
        width: 100%;
        height: 200px;
        margin-bottom: 15px;
        text-align: left;
      }
      .btn_wrapper {
        text-align: center;
        margin-bottom: 0;
      }
      .btn {
        min-width: 200px;
      }
    }
    .list_btn {
      li {
        margin-bottom: 10px;
      }
      .fa {
        margin-right: 5px;
      }
      .btn {
        @media #{$scr_sp} {
          margin-bottom: 10px;
        }
      }
    }
    .my_companion {
      .inner {
        @media #{$scr_sp} {
          display: block;
        }
      }
      .block_left {
        @media #{$scr_sp} {
          width: 100%;

        }
      }
    }
  }
}

/* ---------------------------------------------------------
   愛鳥編集
----------------------------------------------------------*/
.gallery {
  .my_companion {
    &.main-photo {
      .inner {
        text-align: center;
        display: block;
      }
      .img {
        margin-bottom: 0;
        max-width: 300px;
        margin: 0 auto;
        @media #{$scr_sp} {
          width: 100%;
        }
      }
    }
    .inner {
      margin-bottom: 0;
    }
    .list_gallery {
      margin-bottom: 10px;
    }
    .btn_wrapper {
      margin-bottom: 0;
    }

  }
}



/* ---------------------------------------------------------
   メッセージ
----------------------------------------------------------*/
.message {
  .main {
    .table_default {
      margin-bottom: 0;
      .col01 {
        width: 120px;
      }
      td {
        &.date{
          width: 130px;
          text-align: center;
        }
        &.delete , &.fukugen{
          width: 90px;
          text-align: center;
          a {
            color:$color_text-light;
          }
          .close {
            fill:$color_text-light;
            width: 10px;
            height: 10px;
            margin-right: 5px;
            vertical-align: 0;
          }
          .fa {
            margin-right: 5px;
          }
        }
      }
    }
    .pageng {
      margin: 0 2px 2em;
    }
  }
}
/* ---------------------------------------------------------
   プロフィール編集・愛鳥の新規登録 ・退会
----------------------------------------------------------*/
.prof , .companion , .withdrawal{
  .main {
    .btn {
      @include font-size(1.6);
      min-width:200px;
    }
    .dl_default {
      dt {
        @media #{$scr_pc} {
          width: calc(25% - 2px);
        }
      }
      dd {
        position: relative;
        @media #{$scr_pc} {
          width: 75%;
        }
        a {
          position: absolute;
          right:25px;
          top:25px;
        }
        .caption {
          margin-bottom: 0;
          color: $color_text-light;
          line-height: 1.5;
          // &:first-child {
          //   margin-top: 10px;
          // }
        }
        input{
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .birthday {
        input {
          margin-bottom: 0;
        }
        .form_block {
          margin-bottom: 0;
          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
      .img {
        border: none;
        background: none;
      }
      .img_path {

      }
      #contain_img {
        margin-bottom: 0;
        .sentaku {
          margin-top: 10px;
          margin-bottom: 20px;
          color: $color_sub;
        }
        .chuigaki {
          margin-bottom: 20px;
        }
        .comment {
          margin-bottom: 0;
        }
      }
      .intro_text {
        width: 100%;
        height: 140px;
      }
    }
  }
}
/* ---------------------------------------------------------
   愛鳥の新規登録
----------------------------------------------------------*/
.companion {
  .main {
    #bbs_bird {
      margin-bottom: 0;
    }
    #txt_bird_intro {
      height: 150px;
    }
  }
}
/* ---------------------------------------------------------
   退会
----------------------------------------------------------*/
.withdrawal {
  .main {
    .reason {
      height: 150px;
    }
  }
}
/* ---------------------------------------------------------
   お問い合わせ
----------------------------------------------------------*/
.contact {
  .main {
    .dl_default {
      dt {
        @media #{$scr_pc} {
          width: calc(30% - 2px);
        }
      }
      dd {
        position: relative;
        @media #{$scr_pc} {
          width: 70%;
        }
        a {
          position: absolute;
          right:25px;
          top:25px;
        }
        .caption {
          margin-bottom: 0;
          color: $color_text-light;
          line-height: 1.5;
          // &:first-child {
          //   margin-top: 10px;
          // }
        }
        input{
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        ul {
          margin-bottom: 0;
        }
        div.checkbox {
          margin-bottom: 0;
        }
      }
      #ContactMessage {
        height: 150px;
      }
    }
    .btn_back {
      margin-right: 30px;
      background: $color_light_gray;
      @media #{$scr_sp} {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
}
/* ---------------------------------------------------------
   コミュニティー
----------------------------------------------------------*/
.bbs {
  .main {
    .ad {
      a {
        font-size: 1.2em;
      }
    }
    .text_ad {
      margin-top: 2em;
      @media #{$scr_sp} {
        margin-top: 0;
      }
    }
    .title_blue {
      margin-bottom: 0;
      font-size: 2.2rem;
      @media #{$scr_sp}{
        font-size: 1.8rem;
        padding: 12px;
      }
      span {
        @media #{$scr_sp} {
          display: inline-block;
        }
      }
      .desc , .asc {
        padding: 0 10px;
        background: #fff;
        color: $color_blue;
        text-decoration: none;
        border-radius: 6px;
        @media #{$scr_pc} {
          position: absolute !important;
          right: 20px !important;
          top: 22px !important;
        }
        @media #{$scr_sp} {
          display: inline-block;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .title_lvl02 {
      .new {
        position: static;
        margin: 0 5px;
      }
    }
    .box_search {
      background: #fff;
      #txt_search {
        @media #{$scr_pc} {
          width: 360px;
          font-size: 1.4rem;
          padding-left: 10px;
          box-sizing: border-box;
          margin-right: 30px;
        }
      }
      .select_cat {
        font-size: 1.4rem;
        @media #{$scr_sp} {
          margin-top: 10px;
        }
      }
      label {
        line-height: 45px;
        @media #{$scr_sp} {
          line-height: 1;
        }
      }
      .submit {
        @media #{$scr_sp} {
          margin-top: -10px;
        }
      }
    }
    .btn_wrapper {
      padding: 3px;
      background-color: $color_main;
      .btn_wrapper_border{
        border: solid 1px #fff;
        margin-bottom: 0;
        padding: 27px;
        box-sizing: border-box;
        @media #{$scr_sp}{
          padding: 27px 10px;
        }
      }
      .text {
        display: inline-block;
        margin-bottom: 15px;
        position: relative;
        color: #fff;
        font-size: 1.8rem;
        font-weight: bold;
        @media #{$scr_sp}{
          font-size: 1.6rem;
          margin-left: 20px;
        }
        // &:before , &:after{
        //   position: absolute;
        //   content:"/";
        //   left:-1em;
        //   top:0;
        // }
        // &:before {
        //   transform: scale(-1, 1);
        // }
        // &:after{
        //   left:auto;
        //   right:-1em;
        // }
        &:before{
          content: '';
          display: inline-block;
          background: url(/assets/img/common/reply_icon_white.svg) no-repeat;
          width: 22px;
          height: 20px;
          top: 50%;
          transform: translateY(-50%);
          background-size: contain;
          position: absolute;
          left: -25px;
          @media #{$scr_sp}{
            widows: 17px;
            height: 16px;
            left: -20px;
          }
        }
      }
      .btn_wrapper_bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        @media #{$scr_sp}{
          display: block;
        }
      }
      .touroku,
      .login_btn {
        margin-bottom: 0;
        .btn {
          color: $color_main;
          background-color: #fff;
          outline: 1px solid #fff;
          outline-offset: -4px;
          @include font-size(1.4);
          padding: 10px 19px;
          @media #{$scr_sp}{
            width: 90%;
            padding: 12px 19px;
            box-sizing: border-box;
            margin: 0 auto;
          }
          svg{
            fill:$color_main;
          }
        }

      }
      .touroku{
        @media #{$scr_sp}{
          width: 100%;
          margin-right: 0;
          display: inline-block;
        }
      }
      .login_btn{
        margin-right: 20px;
        position: relative;
        transition: all .5s ease;
        @media #{$scr_sp}{
          margin-right: 0;
          margin-bottom: 15px;
        }
        &:hover{
            opacity: 0.7;
            svg{
              fill:$color_main;
            }
          }
        svg{
          top: 50%;
          transform: translateY(-50%);
          left: 20px;
          position: absolute;
          fill:$color_main;
          @media #{$scr_sp}{
            left: 50px;
          }
          }
        a{
          padding: 10px 30px;
          box-sizing: border-box;
          color: $color_main;
          background-color: #fff;
          @include font-size(1.4);
          outline: 1px solid #fff;
          outline-offset: -4px;
          display: inline-block;
          svg{
            fill:$color_main;
          }
          @media #{$scr_sp}{
            display: inline-block;
            width: 90%;
            padding: 13px 30px;
          }
        }
      }
    }
    .table_default {
      margin-bottom: 0px;
      th {
        a {
          color: #FFF;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .col01 {
        position: relative;
        text-align: left;
        a {
          color: $color_main;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        .new {
          position: absolute;
          left:-20px;
          top:-10px;
        }
      }
      .reply {
        width: 60px;
        text-align: center;
      }
      .date {
        width: 130px;
        text-align: center;
      }
      .name {
        text-align: center;
      }
    }
    .pageng {
      // margin-left: 2px;
      // margin-right: 2px;
    }
    .my_companion {

    }
  }
}
/* ---------------------------------------------------------
   コミュニティー詳細
----------------------------------------------------------*/
.bbs_detail {
  .box{
    background-color: #fff;
  }
  .main {
    .my_companion {
      position: relative;
      .modal {
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        position: absolute;
        top: 0;
        // background: red;
        background: transparent;
        // z-index: 1;
        display: none;
      }
      .bbs-post{
        background-color: #FFFEF5;
        padding: 25px 20px 30px;
        box-sizing: border-box;
        margin-bottom: 50px;
        @media #{$scr_sp}{
          padding: 20px 10px 30px;
        }
      }
    }
    .my_companion .inner {
      margin: 0;
      display: block;
    }
    .block_left{
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: 0;
      justify-content: space-between;
      @media #{$scr_sp}{
        margin-bottom: 10px;
      }
      .img{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-bottom: 0;
        @media #{$scr_sp}{
          width: 40px;
          height: 40px;
        }
        a{
          width: 100%;
          height: 100%;
          display: inline-block;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .block_header_l{
        display: flex;
        margin-bottom: 0;
        @media #{$scr_sp}{
          flex-wrap: wrap;
          width: 70%;
        }
      }
      .block_header_r{
        margin-bottom: 0;
        display: flex;
        @media #{$scr_sp}{
          display: block;
          width: 30%;
        }
        dl{
          display: flex;
          font-size: 1.4rem;
          font-weight: bold;
          color: #535353;
          margin-right: 20px;
          align-items: center;
          @media #{$scr_sp}{
            margin-right: 0;
            margin-bottom: 0;
          }
          &:last-of-type{
            margin-right: 0;
            img{
              vertical-align: middle;
            }
          }
          img{
            vertical-align: baseline;
          }
          dt{
            margin-right: 5px;
            @media #{$scr_sp}{
              padding-left: 4px;
              margin-right: 0;
              font-size: 1.3rem;
              // width: 70%;
              margin-right: 10px;
            }
          }
          dd{
            color: $color_sub;
            font-size: 1.7rem;
            @media #{$scr_sp}{
              font-size: 1.5rem;
              // width: 30%;
              // text-align: center;
              text-align: left;
            }
          }
        }
      }
    }
    .date_topic {
      font-size: 1.2rem;
      color: #929292;
      margin-bottom: 0;
    }
    .post_data{
      margin-bottom: 0;
      margin-left: 10px;
    }
    .list_btn {
      display: flex;
      margin-bottom: 0;
      align-items: center;
      @include font-size(1.2);
      li {
        margin-bottom: 0;
        color: #929292;
        &.host {
          @include font-size(1);
          color: $color_white;
          background: $color_main;
          width: fit-content;
          border-radius: 2px;
          padding: 0 5px;
          margin-left: 10px;
          @media #{$scr_sp} {
          }
        }
        .btn {
          padding: 10px;
        }
        a{
          font-weight: bold;
        }
      }
      .fa {
        margin-right: 5px;
      }
      .sumi {
        text-align: center;
        background: $color_bg;
        padding: 10px;
        a{
          color: #929292;
          border: solid 1px #929292;
        }
      }
    }
    .edit_area{
      margin-bottom: 0;
      display: flex;
      margin-left: 15px;
      @media #{$scr_sp}{
        margin-left: 0;
        margin-top: 10px;
        width: 100%;
      }
      .btn_edit,
      .btn_delete {
        background-color: transparent;
        border: solid 1px #929292;
        border-radius: 6px;
        color: #535353;
        font-size: 1.2rem;
        padding: 3px 10px;
        transition: ease all .3s;
        &:hover{
          opacity: 0.7;
        }
      }
      .btn_edit{
        margin-right: 10px;
      }
    }
    .block_right {
      width: 100%;
      @media #{$scr_sp}{
        margin-bottom: 0;
      }
      .title_topic {
        @include font-size(2.4);
        margin: 0 0 10px;
      }
      .youtube_topic {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        iframe {
          position: absolute;
          top: 0;
          right: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }
      .sp-only.admin_info {
        @media #{$scr_sp} {
          display: flex !important;
          margin: 0 auto;
        }
        .img {
          @media #{$scr_sp} {
            width: 40%;
            margin-right: 5%;
          }
        }
      }
    }
    .text_topic{
      margin-bottom: 40px;
      line-height: 1.6;
      @media #{$scr_sp}{
        margin-bottom: 30px;
      }
    }
    .image_topic{
      a{
        img{
          max-width: 300px;
          max-height: 300px;
        }
      }
    }
    .id_topic {
      color: #929292;
      @include font-size(1);
      text-align: right;
      margin-bottom: 0;
      @media #{$scr_sp}{
        transform: scale(0.9);
      }
    }
    .reaction_area{
      display: flex;
      border-top: 1px dashed #bcbcbc;
      border-bottom: 1px dashed #bcbcbc;
      padding: 25px 40px;
      box-sizing: border-box;
      margin-bottom: 20px;
      @media #{$scr_sp}{
        display: block;
        padding: 30px 10px;
        text-align: center;
      }
    }
    .voting_topic{
      margin-right: 95px;
      margin-bottom: 0;
      @media #{$scr_sp}{
        width: 100%;
      }
      p{
        font-weight: bold;
        line-height: 1;
        margin-bottom: 0;
        @media #{$scr_sp}{
          // font-size: 1.5rem;
        }
        img{
          vertical-align: bottom;
        }
      }
    }
    .voting_box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 30px;
      margin-bottom: 0;
      position: relative;
      z-index: 2;
      @media #{$scr_sp} {
        flex-direction: column;
      }
      .total {
        border: 2px solid $color_main;
        background: #F7FBFF 0% 0% no-repeat padding-box;
        border-radius: 17px;
        padding: 15px;
        height: fit-content;
        line-height: 1;
        margin-bottom: 0;
        margin-right: 50px;
        position: relative;
        top: 15px;
        @media #{$scr_sp} {
          width: 40%;
          min-width: 154px;
          margin-right: 0;
          order: 2;
        }
        span {
          @include font-size(1.4);
          font-weight: bold;
          color: $color_main;
          display: inline-block;
          margin-bottom: 5px;
          border-bottom: 1px solid #45aaf9;
          padding-bottom: 6px;
          @media #{$scr_sp} {
            display: inherit;
            text-align: center;
          }
          img {
            vertical-align: middle;
          }
        }
        p {
          @include font-size(2.5);
          font-weight: bold;
          margin-bottom: 0;
          text-align: center;
          line-height: 1;
          color: #45AAF9;
        }
      }
    }
    .voting_detail {
      width: 100%;
      max-width: 460px;
      margin-bottom: 0;
      position: relative;
      @media #{$scr_sp} {
        order: 1;
      }
      ul {
        display: flex;
        margin-bottom: 0;
        @media #{$scr_sp} {
          justify-content: space-between;
        }
        &.voting_btn {
          li {
            @include font-size(1.2);
            position: relative;
            // width: 17%;
            cursor: pointer;
            text-align: center;
            @media #{$scr_sp} {
              @include font-size(1.0);
              // font-weight: normal;
              -webkit-tap-highlight-color:rgba(0,0,0,0);
            }
            &:not(:last-child) {
              margin-right: 19px;
              @media #{$scr_sp} {
                margin-right: 10px;
              }
            }
            &:before {
              content: "";
              display: block;
              width: 58px;
              height: 58px;
              border-radius: 50%;
              background-color: #ecf6fe;
              margin-bottom: 5px;
              // position: absolute;
              top: 20px;
              @media #{$scr_sp} {
                width: 51px;
                height: 51px;
                background-size: cover;
              }
              @media #{$scr_sp_min}{
                 width: 41px;
                 height: 41px;
              }
            }
            &.good {
              &:before {
                background-image: url(/assets/img/topics/reaction01@2x.png);
                background-size: contain;
              }
            }
            &.fight {
              &:before {
                background-image: url(/assets/img/topics/reaction02@2x.png);
                background-size: contain;
              }
            }
            &.awesome {
              &:before {
                background-image: url(/assets/img/topics/reaction03@2x.png);
                background-size: contain;
              }
            }
            &.me_too {
              &:before {
                background-image: url(/assets/img/topics/reaction04@2x.png);
                background-size: contain;
              }
            }
            &.sad {
              &:before {
                background-image: url(/assets/img/topics/reaction05@2x.png);
                background-size: contain;
              }
            }
            &:hover {
              &:before {
                // transition: .1s;
                // background-color: #ffeaea;
              }
            }
            &.hLight {
              &:after {
                transition: .1s;
                background-color: #ffeaea;
              }
            }
          }
        }
        &.voting_counter {
          position: absolute;
          right: -30px;
          top: -8px;
          width: 100%;
          max-width: 460px;
          margin: auto;
          z-index: 2;
          // padding: 0 10px;
          @media #{$scr_sp} {
            width: 90%;
            right: 0;
          }
          li {
            @include font-size(1.1);
            font-weight: 700;
            width: 9%;
            color: $color_main;
            text-align: center;
            position: relative;
            @media #{$scr_sp}{
               width: 11%;
            }
            &:not(:last-child) {
              margin-right: 45px;
              @media #{$scr_sp} {
                margin-right: 0;
                width: 11%;
              }
            }
            div.number {
              border-radius: 8px;
              cursor: pointer;
              margin-bottom: 0;
              background-color: $color_main;
              color: #fff;
              font-size: 1.1rem;
              position: relative;
              @media #{$scr_sp} {
                margin-bottom: 10px;
              }
              &:after {
                content: "";
                display: inline-block;
                width: 0px;
                height: 0px;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid #fff;
                position: absolute;
                top: 7px;
                margin-left: 3px;
                @media #{$scr_sp}{
                  margin-left: 2px;
                }
              }
              &:hover {
                opacity: 0.6;
                @media #{$scr_sp} {
                  opacity: 1;
                }
              }
              &.lOpen {
                & + div.member {
                  opacity: 1;
                  display: block;
                }
              }
              &.zero {
                color: #fff;
                pointer-events: none;
                &:after {
                  display: none;
                }
              }
            }
            div.member {
              width: 270px;
              padding: 20px;
              box-sizing: border-box;
              // max-height: 390px;
              background-color: rgba(255,255,255,0.9);
              border: 1px solid #eee;
              position: absolute;
              bottom: 50%;
              left: 120%;
              transform: translate(0%, 30%);
              z-index: 3;
              opacity: 0;
              display: none;
              transition: .1s;
              margin-bottom: 0;
              @media #{$scr_sp} {
                width: 80vw;
                height: fit-content;
                position: fixed;
                top: 50%;
                bottom: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
              .head_area {
                position: relative;
                margin-bottom: 0;
              }
              p {
                @include font-size(1.6);
                font-weight: bold;
                color: $color_text;
                text-align: left;
                margin-bottom: 10px;
              }
              img.close {
                position: absolute;
                top: -10px;
                right: -10px;
                cursor: pointer;
                transition: .1s;
                &:hover {
                  opacity: 0.6;
                  @media #{$scr_sp} {
                    opacity: 1;
                  }
                }
              }
              ul {
                display: block;
                padding-right: 10px;
                li {
                  @include font-size(1.4);
                  font-weight: normal;
                  width: inherit;
                  text-align: left;
                  color: $color_text;
                  line-height: 1.2;
                  &:not(:last-child) {
                    margin-bottom: 15px;
                  }
                }
              }
            }
            .scrollarea {
              max-height: 360px;
              overflow-y: scroll;
              margin-bottom: 0;
              @media #{$scr_sp} {
                max-height: 300px;
              }
              &::-webkit-scrollbar {
                width: 7px;
              }
              &::-webkit-scrollbar-thumb {
                background: $color_main;
                border-radius: 3px;
              }
              &::-webkit-scrollbar-track {
                background: rgba(100,100,100, .3);
                border-radius: 10px;
              }
              ul {
                padding-right: 40px;
              }
            }
          }
        }
      }
    }
    .sns_topic {
      position: relative;
      z-index: 1;
      margin-bottom: 0;
      @media #{$scr_sp} {
        flex-direction: column;
        padding-top: 40px;
      }
      p {
        font-weight: bold;
        margin-bottom: 25px;
        line-height: 1;
        @media #{$scr_sp} {
          margin-bottom: 15px;
          font-size: 1.5rem;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 5px;
          background: url(/assets/img/topics/icon_share_blue.svg) no-repeat;
          background-position: center top;
          background-size: contain;
        }
      }
      ul {
        display: flex;
        margin-bottom: 0;
        @media #{$scr_sp}{
          justify-content: center;
          margin-top: 20px;
        }
        li {
          margin-left: 14px;
          line-height: 1;
          width: 32px;
          &:first-child {
            margin-left: 0;
          }
          img{
            width: 100%;
          }
          * {
            margin: 0;
            line-height: 1;
          }
          a{
            display: inline-block;
            width: 100%;
            padding-right: 0;
          }
        }
      }
      a[target="_blank"] {
        background-image: none !important;
      }
    }
    .reaction_area_bottom{
      margin-bottom: 10px;
      @media #{$scr_sp}{
        flex-wrap: wrap;
      }
      ul{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
        @media #{$scr_sp}{
          flex-wrap: wrap;
        }
        li{
          margin-right: 10px;
          position: relative;
          @media #{$scr_sp}{
            margin-right: 6px;
          }
          @media #{$scr_sp}{
            margin-bottom: 5px;
          }
          &:last-child{
            margin-right: 0;
          }
          a{
            background-color: #fff;
            color: $color_sub;
            border: solid 1px $color_sub;
            border-radius: 6px;
            padding: 6px 9px;
            font-size: 1.2rem;
            font-weight: bold;
            @media #{$scr_sp}{
              font-size: 1rem;
              padding: 6px 5px;
            }
            i{
              &:before{
                margin-right: 2px;
              }
            }
            &:hover{
              background-color: $color_sub;
              color: #fff;
              text-decoration: none;
              & + .reaction_popup{
                display: block;
                @media #{$scr_sp}{
                  display: none;
                }
              }
            } 
          }
          .reaction_popup{
            display: none;
            position: absolute;
            font-size: 1.2rem;
            color: #383838;
            top: -35px;
            width: calc(206/58*100%);
            left: 50%;
            transform: translateX(-50%);
            background: #fff;
            border: solid 1px #DDDDDD;
            border-radius: 4px;
            text-align: center;
            z-index: 1;
            &:after{
              left: 50%;
              transform: translateX(-50%);
              bottom: -10px;
              position: absolute;
              content: '';
              display: inline-block;
              width: 18px;
              height: 10px;
              background: url(/assets/img/topics/popup_arrow.svg) no-repeat;
            }
          }
        }
        li.report_li{
          margin-right: 0;
          a{
            color: #535353;
            border: solid 1px #929292;
            &:before{
              margin-right: 2px;
              content: '';
              display: inline-block;
              width: 11px;
              height: 11px;
              background: url(/assets/img/topics/report_icon.svg) no-repeat;
            }
            &:hover{
              opacity: 0.5;
              background-color: transparent;
              color: #929292;
            }
          }
        }
      }
      .sumi {
        .reaction_popup{
          width: 100%;
        }
        a{
          color: #929292;
          border: solid 1px #929292;
          cursor: pointer;
          &:hover{
              cursor: pointer;
              background-color: $color_white !important;
              color: #929292 !important;
            }
        }
      }
      
    }
    .delete_topic {
      text-align: right;
      margin: 0;
    }
    .btn_write {
      margin: 0;
      margin-bottom: 0;
      padding: 3px;
      background: $color_main;
      .btn {
        display: block;
        background: $color_main;
        border: solid 1px #fff;
      }
      & > .btn{
        font-size: 1.6rem;
        position: relative;
        padding: 10px 0;
        width: 100%;
        img{
          vertical-align: middle;
          width: 17px;
          height: auto;
        }
      }
      .login_btn{
        svg{
          @media #{$scr_sp}{
            left: 40px;
          }
        }
      }
    }
    .reply {
      .new {
        position: relative;
        top:2px;
        left: 5px;
        margin-right:10px;
      }
      .title_topic {
        @include font-size(1.8);
      }
      .title_blue {
        background: #fff;
        color: $color_text;
        display: flex;
        align-items: center;
        padding-bottom: 0;
        @media #{$scr_sp}{
          flex-wrap: wrap;
          padding-top: 20px;
        }
        img{
          vertical-align: baseline;
        }
        span {
          position: static;
          right: auto;
          top:auto;
          font-weight: normal;
          color: $color_text;
          font-size: 2.2rem;
          @media #{$scr_sp}{
            font-size: 1.8rem;
          }
        }
        p{
          font-size: 1.4rem;
          font-weight: normal;
          display: inline-block;
          margin-bottom: 0;
          margin-left: 10px;
        }
        a {
          position: static;
          right: auto;
          top:auto;
          border: solid 1px $color_main;
          padding: 2px 10px;
          @media #{$scr_sp}{
            margin-top: 10px;
          }
        }
      }
      .block_left{
        margin-bottom: 10px;
      }
      .bbs-post-reply{
        background: #fff;
        padding-top: 0;
        padding-bottom: 0;
        .inner{          
          border-bottom: 1px dashed $color_text-light;
          padding-top: 20px;
        }
        &:first-of-type{
          padding-top: 15px;
          .inner{
            border-top: 1px dashed $color_text-light;
          }
        }
      }
      .box {
        margin-bottom: 0;
        // border-bottom:1px dashed $color_text-light;
        position: relative;
      }
      .reply_modal {
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        position: absolute;
        top: 0;
        left: 0;
        // background: red;
        background: transparent;
        z-index: 1;
        display: none;
      }
      .text_topic{
        margin-bottom: 20px;
      }
      .reaction {
        display: block;
        //align-items: flex-start;
        position: relative;
        margin-bottom: 0;
        @media #{$scr_sp} {
          justify-content: flex-end;
        }
        .number {
          margin-right: 50px;
          cursor: pointer;
          position: relative;
          z-index: 2;
          margin-bottom: 10px;
          @media #{$scr_sp} {
            -webkit-tap-highlight-color:rgba(0,0,0,0);
            margin-right: auto;
          }
          span {
            padding: 9px 10px;
            position: relative;
            transition: .1s;
            font-size: 1.2rem;
          }
          span.good {
            border: solid 1px $color_main;
            border-radius: 6px;
            color: $color_main;
            transition: all .5s ease;
            cursor: pointer;
            padding: 5px 10px;
            &:before {
              content: "";
              display: inline-block;
              width: 14px;
              height: 17px;
              background: url(/assets/img/topics/icon_thumbs_up.svg) no-repeat;
              background-size: contain;
              margin-right: 5px;
              vertical-align: sub;
            }
            &:hover{
              background-color: $color_main;
              color: #fff;
              &:before{
              background: url(/assets/img/topics/icon_thumbs_up_white.svg) no-repeat;
              }
            }
            &.active{
              background-color: $color_main;
              color: $color_white;
              &:before{
                background: url(/assets/img/topics/icon_thumbs_up_white.svg) no-repeat;
              }
            }
          }
          span.good_count {
            @include font-size(1.4);
            display: block;
            width: auto;
            padding: 10px 0 0 0;
            text-decoration: underline;
            color: $color_main;
            @media #{$scr_sp}{
              @include font-size(1.1);
            }
            &:after {
              color: #929292;
              content: "人がいいね！しています";
              display: inline-block;
              height: 15.5px;
              overflow-y: hidden;
              font-size: 12px;
              @media #{$scr_sp}{
                @include font-size(1.1);
                 height: 14.5px;
              }
            }
            &.lOpen {
              & + .member {
                display: block;
                opacity: 1;
              }
            }
            &.zero {
              display: none;
            }
          }
          span {
            // font-weight: bold;
            // position: relative;
            // transition: .1s;
            // &:before {
            //   content: "";
            //   display: inline-block;
            //   width: 33px;
            //   height: 33px;
            //   background: url(/assets/img/topics/icon_thumbs_up@2x.png) no-repeat;
            //   background-size: contain;
            //   margin-right: 5px;
            //   vertical-align: text-bottom;
            // }
            // &:after {
            //   content: "";
            //   display: inline-block;
            //   width: 0px;
            //   height: 0px;
            //   border-left: 5px solid transparent;
            //   border-right: 5px solid transparent;
            //   border-top: 5px solid #fff;
            //   position: absolute;
            //   top: 9px;
            //   margin-left: 5px;
            // }
            // &:hover {
            //   opacity: 0.6;
            //   @media #{$scr_sp} {
            //     opacity: 1;
            //   }
            // }
          }
        }
        .member {
          width: 270px;
          padding: 20px;
          box-sizing: border-box;
          background-color: rgba(255,255,255,0.9);
          border: 1px solid #eee;
          position: absolute;
          top: 0;
          left: 80px;
          z-index: 3;
          opacity: 0;
          display: none;
          transition: .1s;
          margin-bottom: 0;
          @media #{$scr_sp} {
            width: 80vw;
            height: fit-content;
            position: fixed;
            top: 50%;
            bottom: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .head_area {
            position: relative;
            // margin-bottom: 0;
          }
          p {
            @include font-size(1.6);
            font-weight: bold;
            color: $color_text;
            text-align: left;
            margin-bottom: 10px;
          }
          img.close {
            position: absolute;
            top: -10px;
            right: -10px;
            cursor: pointer;
            transition: .1s;
            &:hover {
              opacity: 0.6;
            }
          }
          ul {
            display: block;
            padding-right: 30px;
            margin-bottom: 0;
            li {
              @include font-size(1.4);
              width: inherit;
              text-align: left;
              color: $color_text;
              line-height: 1.2;
              &:not(:last-child) {
                margin-bottom: 15px;
              }
            }
          }
          .scrollarea {
            max-height: 370px;
            overflow-y: scroll;
            margin-bottom: 0;
            &::-webkit-scrollbar {
              width: 7px;
            }
            &::-webkit-scrollbar-thumb {
              background: $color_main;
              border-radius: 3px;
            }
            &::-webkit-scrollbar-track {
              background: rgba(100,100,100, .3);
              border-radius: 10px;
            }
          }
        }
      }
      .block_header_l{
        width: 100%;
      }
      .block_right{
        margin-bottom: 0;
      }
      .reply_bottom{
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
      }
      .reply_bottom_l,
      .reply_bottom_r{
        margin-bottom: 0;
        @media #{$scr_sp_min}{
          width: 50%;
        }
      }
      .id_topic{
        margin-bottom: 0;
      }
    } // reply
  }
}
.good_popup,
.delete_popup{
  position: fixed;
  bottom: 70px;
  right: 20px;
  font-size: 1.4rem;
  font-weight: bold;
  //opacity:0;
  display: inline-block;
  border-radius: 7px;
  margin-bottom: 0;
  //transition: ease all .5s;
  @media #{$scr_sp}{
    font-size: 1.3rem;
    bottom: auto;
    top: -20px;
    right: 60px;
    margin-bottom: 0;
    z-index: 5000;
    position: absolute;
  }
  &.fadein{
    //opacity: 1;
  }
  p{
    margin-bottom: 0 !important;
    position: relative;
    padding: 25px 20px 25px 60px;
    box-sizing: border-box;
    @media #{$scr_sp}{
      padding: 20px 20px 20px 55px;
    }
    &:before{
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 37px;
      height: 37px;
      display: inline-block;
      @media #{$scr_sp}{
        width: 32px;
        height: 32px;
        background-size: contain;
      }
    }
  }
}
.good_popup{
  background: $color_main;
  color: $color_white;
  p{
    &:before{
      background-size: contain;
      background: url(/assets/img/topics/icon_thanks.svg) no-repeat;
      @media #{$scr_sp}{
        background-size: contain;
      }
    }
  }
}
.delete_popup{
  background: #A7A7A7;
  color: $color_white;
  p{
    &:before{
      background-size: contain;
      background: url(/assets/img/topics/icon_delete.svg) no-repeat;
      @media #{$scr_sp}{
        background-size: contain;
      }
    }
  }
  & + .box{
    background: $color_main;
  }
}
.bbs_entry{
  .title_blue{
    background: #fff;
    color: $color_text;
    display: flex;
    align-items: center;
    padding: 0 0 10px 0;
    margin-top: 0;
    border-bottom: 1px dashed $color_text-light;
    @media #{$scr_sp}{
      padding: 0 0 10px !important;
    }
    img{
      vertical-align: baseline;
    }
    & + .mb00{
      padding: 20px 0;
      border-bottom: 1px dashed $color_text-light;
    }
  }
  .box{
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }
  #BbsPostPostForm{
    background-color: #fff;
    padding-bottom: 10px;
     .btn_wrpper{
      input{
        width: 100%; 
      }
     }
  }
  .form_inner{
    padding: 0 15px;
    dl{
      dt{
        border-left: none;
        color: $color_text;
        border-bottom: 1px dashed $color_text-light;
        padding-left: 0;
        @media #{$scr_sp}{
          border-bottom: none;
          padding-bottom: 10px;
        }
      }
      dd{
        border-bottom: 1px dashed $color_text-light;
        padding-right: 0;
        @media #{$scr_sp}{
          border-left: none;
          padding-left: 0;
          padding-top: 0;
        }
        p{
          font-size: 1.4rem;
          margin-bottom: 5px;
          input{
            padding: 10px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  .form_btn_area{
    width: 100%;
    padding: 0 15px 30px;
    box-sizing: border-box;
    margin-bottom: 0 !important;
    button.btn{
      width: 100%;
      outline: 1px solid #fff;
      outline-offset: -4px;
      img{
        vertical-align: middle;
      }
    }
  }
  & > div{
    margin-bottom: 50px !important;
  }
  & + .box{
    background-color: $color_main;
  }
}
/* ---------------------------------------------------------
   とりっちメンバーブログ更新情報
----------------------------------------------------------*/
.blog {
  h1 + p {
    text-align: center;
  }
  .blog_title {
    position: relative;
    span {
      position: absolute;
      right: 0;
      top: 25px;
      font-size: 14px;
      font-size: 1.4rem;
      @media #{$scr_sp} {
        position: static;
        display: block;
        text-align: center;
      }
    }
  }
  .blog_list {
    display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-line-pack: justify;
      align-content: space-between;
      margin-bottom: 40px;
      li {
        width: 420px;
        background: #fff;
        margin-bottom: 15px;
        padding: 15px;
        box-sizing: border-box;
        img {
          float: left;
          margin-right: 15px;
        }
        figure {
          position: relative;
          .new {
            position: absolute;
            top: -20px;
            left: -40px;
            @media #{$scr_sp} {
            left: -20px;
            }
          }
        }
        .text {
          padding-left: 114px;
          margin-bottom: 0;
          a[target="_blank"] {
            background-image: none;
          }
        }
        time {
          display: inline-block;
          font-size: 14px;
          font-size: 1.4rem;
          margin-right: 10px;
          margin-bottom: 5px;
          color: #aaa;
        }
        .title {
          display: block;
          margin-bottom: 5px;
          line-height: 1;
        }
        a {
          color: #444;
          text-decoration: none;
        }
        .name {
          font-size: 1.4rem;
          color: #78c0f9;
        }
    }
  }
}

/* ---------------------------------------------------------
   トピック一覧
----------------------------------------------------------*/
.topic {
  .box_search{
    background-color: #fff;
    .submit{
      margin-bottom: 0 !important;
    }
  }
  .topic_title {
    position: relative;
    span {
      position: absolute;
      right: 0;
      top: 25px;
      font-size: 14px;
      font-size: 1.4rem;
      @media #{$scr_sp} {
        position: static;
        display: block;
        text-align: center;
      }
    }
  }
  .list_topic {
    margin-bottom: 0;
    .ad {
      a {
        font-size: 1.2em;
      }
    }
    a {
      position: relative;
        display: block;
        background: #fff;
        padding: 9px 10px;
        text-decoration: none;
        article{
          line-height: normal;
        }
      }
    .title,.icon_category {
      margin-bottom: 0;
    }
    .title {
      margin-top: 3px;
      line-height: normal;
    }
    .name {
      display: inline-block;
    }
  }
  .list_topic a:hover {
    text-decoration: none;
  }
  .title_lvl02 {
    .new {
      position: static;
      //left: -35px;
      //top:-20px;
      @media #{$scr_sp} {
        //position: static;
      }
    }
  }
  .btn_wrapper{
    padding: 3px
  }
  .btn_wrapper_border{
    border: solid 1px #fff;
    margin-bottom: 0 !important;
    padding: 27px;
    box-sizing: border-box;
    @media #{$scr_sp}{
      padding: 27px 10px;
    }
  }
  .text {
        display: inline-block;
        margin-bottom: 15px !important;
        position: relative;
        color: #fff;
        font-size: 1.8rem;
        font-weight: bold;
        @media #{$scr_sp}{
          font-size: 1.6rem;
          margin-left: 20px;
        }
        &:before{
          content: '';
          display: inline-block;
          background: url(/assets/img/common/reply_icon_white.svg) no-repeat;
          width: 22px;
          height: 20px;
          top: 50%;
          transform: translateY(-50%);
          background-size: contain;
          position: absolute;
          left: -25px;
          @media #{$scr_sp}{
            widows: 17px;
            height: 16px;
            left: -20px;
          }
        }
      }
      .btn_wrapper_bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0 !important;
        @media #{$scr_sp}{
          display: block;
        }
      }
      .touroku,
      .login_btn {
        margin-bottom: 0 !important;
        .btn {
          color: $color_main;
          background-color: #fff;
          outline: 1px solid #fff;
          outline-offset: -4px;
          @include font-size(1.4);
          padding: 10px 19px;
          @media #{$scr_sp}{
            width: 90%;
            padding: 12px 19px;
            box-sizing: border-box;
          }
          svg{
            fill:$color_main;
          }
        }

      }
      .touroku{
        @media #{$scr_sp}{
          width: 100%;
          margin-right: 0;
          display: inline-block;
        }
      }
      .login_btn{
        margin-right: 20px;
        position: relative;
        transition: all .5s ease;
        @media #{$scr_sp}{
          margin-right: 0;
          margin-bottom: 15px !important;
        }
        &:hover{
            opacity: 0.7;
            svg{
              fill:$color_main;
            }
          }
        svg{
          top: 50%;
          transform: translateY(-50%);
          left: 20px;
          position: absolute;
          fill:$color_main;
          @media #{$scr_sp}{
            left: 50px;
          }
          }
        a{
          padding: 10px 30px;
          box-sizing: border-box;
          color: $color_main;
          background-color: #fff;
          @include font-size(1.4);
          outline: 1px solid #fff;
          outline-offset: -4px;
          display: inline-block;
          svg{
            fill:$color_main;
          }
          @media #{$scr_sp}{
            display: inline-block;
            width: 90%;
            padding: 13px 30px;
          }
        }
      }
}

/* ---------------------------------------------------------
   個人情報保護方針
----------------------------------------------------------*/
.privacy {
  .list_default {
    li {
      position: static;
      font-weight: bold;
      text-indent: -1em;
      padding-left: 1em;
    }
  }
  .list_default li:before {
    position: static;
    display: none;
  }
  .main {
    p:last-of-type {
      margin-bottom: 100px;
    }
  }
}

/* ---------------------------------------------------------
   運営会社情報
----------------------------------------------------------*/
.company {
  .showly {
    color: #fe86a4;
    font-weight: bold;
  }
  .main {
    h3:last-of-type {
      // margin-bottom: 1rem;
    }
    .dl_default {
    margin-bottom: 100px;
    }
  }
}

/* ---------------------------------------------------------
   広告掲載について
----------------------------------------------------------*/
.ads {
  .main {
    .btn_wrapper {
      margin: 100px 0;
    }
    p{
      margin-bottom: 1em;
    }
    .caption {
      margin-bottom: 0;
    }
    ul {
      list-style-type: disc;
      margin-left: 20px;
      margin-bottom: 1em;
    }
  }
}

/* ---------------------------------------------------------
   利用規約
----------------------------------------------------------*/
.siteuse {
  .list_default {
    li {
      position: static;
      font-weight: bold;
      padding: 20px;
      padding-left: 0;
      p {
        font-weight: normal;
        padding-left: 2rem;
        margin-bottom: 0;
      }
      ol {
        list-style-type: lower-alpha;
        margin-left: 2.5em;
        margin-top: 20px;
        li {
          font-weight: normal;
          border: none;
        }
      }
      .list_style_alpha {
        padding: 0;
      }
      .list_style_number {
        list-style-type: decimal;
        padding-bottom: 20px;
      }
      .list_kinshi_detail {
        p{
          font-weight: bold;
          text-indent: -1em;
          padding-left: 1em;
        }
      }
    }
  }
  .list_default li:before {
    position: static;
    display: none;
  }
  .list_kenri_detail {
    li {
      font-weight: normal;
      text-indent: -1em;
      padding-left: 1em;
    }
  }
  .kaisei {
    text-align: right;
  }
  .fusoku {
    text-align: left;
  }
  .main {
    .fusoku {
      margin-bottom: 0;
    }
  }
}

/* ---------------------------------------------------------
   とりっちのリンク方法
----------------------------------------------------------*/
.link {
  .link_box {
    img {
      display: inline-block;
      @media #{$scr_sp} {
        max-width: 100%;
        height: auto;
      }
    }
    span {
      display: inline-block;
      vertical-align: bottom;
      font-size: 13px;
      font-size: 1.3rem;
      margin-left: 5px;
    }
    div {
      background: #fff;
      padding: 25px 30px;
      margin-top: 10px;
      code {
        color: #444;
        font-size: 13px;
        font-size: 1.3rem;
      }
    }
  }
  .main {
    .btn_wrapper {
      margin: 100px 0;
    }
  }
}
/* ---------------------------------------------------------
   鳥の販売情報について/里親の取引に関するガイドライン
----------------------------------------------------------*/
.hanbai , .satooya {
  .btn_back {
    margin-right: 30px;
    background: $color_light_gray;
    @media #{$scr_sp} {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
  .btn_next {
    background: $color_sub;
  }
}
/* ---------------------------------------------------------
   里親の取引に関するガイドライン
----------------------------------------------------------*/
.satooya {
  .box_scroll {
    height: 400px;
    overflow-y: scroll;
    background: #fff;
    padding: 30px;
    .strong {
      color: $color_main;
    }
  }
}
/* ---------------------------------------------------------
   ポップアップ系（ガイドライン）
----------------------------------------------------------*/
.rule {
  background: none;
  word-wrap: break-word;
  .container {
    //width: 550px;
    margin: 30px;
    padding: 30px;
    border: #eee solid 1px;
  }
  .container > div {
    margin: 0;
    line-height: 1.8;
  }
  .title {
    text-align: center;
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .container .guideline {
    list-style-type: decimal;
    line-height: 1.4;
    margin-left: 20px;
    margin-bottom: 30px;
  }
  .container ul li {
    margin-bottom: 5px;
  }
  .sub_title {
    text-align: left;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .jyoken,.jyoho {
    margin-bottom: 20px;
  }
  .jyoken li{
    list-style-type: none;
    margin-left: 0;
    text-indent: -1em;
    padding-left: 1em;
  }
  .container .jyoho li {
    margin-bottom: 0;
  }
  .btn_wrapper {
    margin-bottom: 10px;
  }
}
/* ---------------------------------------------------------
   鳥の販売情報についてのガイドライン
----------------------------------------------------------*/
.rule_sales {


}

/* ---------------------------------------------------------
   投稿IDについて
----------------------------------------------------------*/



/* ---------------------------------------------------------
   よくある質問
----------------------------------------------------------*/
.guide {
  .main {
    h1 + p {
      margin-bottom: 50px;
    }
    .subnav_guide_wrapper {
      margin-top: 50px;
    }
    .subnav_tab {
      //border-top: 2px solid #78c0f9;
      //border-bottom: none;
      @media #{$scr_sp} {
        width: 600px;
      }
    }
    .title_lvl02 {
      margin-top: 0;
    }
    .title_lvl02:not(first-child) {
      margin-top: 40px;
    }
    .accordion {
      dt {
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 3px;
        cursor: pointer;
        position: relative;
        img {
          position: absolute;
          right: 2%;
          top: 50%;
        }
      }
      dd {
        padding: 20px;
        background: #fff;
        margin-bottom: 3px;
        p {
          text-indent: -1.8em;
          padding-left: 1.8em;
          margin-bottom: 0;
          span {
            font-weight: bold;
          }
        }
      }
    }
    .accordion:last-of-type {
      margin-bottom: 50px;
    }
  }
}

