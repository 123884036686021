@charset "utf-8";

// 使いそうな色設定
// -------------------------
$color_red          : #EA5543 ;
$color_crimson      : #CC0000 ;
$color_blue         : #68B3E3 ;
$color_light_blue   : #78c0f9 ;
$color_green        : #A9CD36 ;
$color_olive        : #79ae35 ;
$color_light_green  : #3dce88 ;
$color_yellow       : #FBC93B ;
$color_light_yellow : #ffeeaf ;
$color_white        : #FFFFFF ;
$color_black        : #444444 ;
$color_pink         : #fe86a4 ;
$color_brown        : #221815 ;
$color_gray         : #999999 ;
$color_light_gray   : #b9b9b9 ;
$color_orange       : #ea7f00 ;
$color_twitter      : #00aced ;
$color_facebook     : #305097 ;

// 使いそうなフォント設定
// -------------------------
$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
$font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
$font-family-hiragino:    "Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3", "YuGothic", "Yu Gothic", "游ゴシック","-Meiryo","メイリオ", sans-serif;
$font-family-mincho:      "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
$font-family-yugothic:    "Open Sans",Helvetica,Arial,"游ゴシック","YuGothic","Hiragino Kaku Gothic ProN","Hiragino Kaku Gothic Pro","Meiryo","メイリオ","ＭＳ ゴシック",sans-serif;
$font-family-yumincho:    "游明朝","YuMincho","ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

// サイトカラー
// -------------------------
$color_main:           #45aaf9;
// $color_sub:            #111111;
$color_sub:            #fe86a4;
$color_text:           #111111;
// $color_text-light:     lighten( $color_text, 40% );
$color_text-light:     #929292;
$color_bg:             #efeee8;
$color_table:           #ffffff;
// リンクカラー
// -------------------------
$color_link:           #78c0f9;

// ボーダーカラー
// -------------------------
$color_border:         #eaeaea;

// フォント設定
// -------------------------
$line-height-base:      1.5;
$font-family-base:      $font-family-yugothic;
$font-weight-thin:      100;

// 幅設定
// -------------------------
$screen:                950px;
$container-width:       $screen;
$alpha-width:           910;
$beta-width:            210;

// 隙間
// -------------------------
$base-clearance1:      12;
$base-clearance2:      20;
$base-clearance3:      30;

// ブレイクポイント
// -------------------------
$scr_sp: "screen and (max-width:767px)";
$scr_pc: "screen and (min-width:768px) , print";
$scr_sp_min: "screen and (max-width: 320px)";