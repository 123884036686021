@charset "utf-8";
/* ---------------------------------------------------------
   base.css
----------------------------------------------------------*/
/* ---------------------------------------------------------
default・option
----------------------------------------------------------*/
html {
  font-family: $font-family-yugothic;
  font-size: 62.5%;
  &.middle {
    font-size: 82.5%;
  }
  &.large {
    font-size: 100%;
  }
}
body {
  @include font-size(1.6);
  padding-top: 5px;
  background: $color_bg url("../img/common/pattern.jpg") repeat-x;
  background-size: 150px 5px;
  color:$color_text;
  //overflow: hidden;
  @media #{$scr_sp} {
    width: 100vw;
    overflow-x: hidden;
  }
}
img {
  vertical-align: top;
}
svg {
  fill:$color_text;
  vertical-align: middle;
  width: 16px;
  height: 16px;
}
a {
  color: $color_main;
  text-decoration: underline;
  transition: all .5s ease ;
  img , p , svg , h1 {
    transition: all .5s ease ;
  }
  &:hover {
    text-decoration: none;
    img {
      opacity: 0.7;
    }
  }
}
fieldset {
  display: inline;
}
input {
  @media #{$scr_sp} {
    max-width: 100%;
  }
}
input , select , textarea {
  background: $color_bg;
  border:1px solid $color_border;
}
textarea {
  width: 100%;
  box-sizing:border-box;
}
.strong {
  color: $color_sub;
}
.wrapper {
  @include clearfix;
  width: 1200px;
  margin: 0 auto;
  @media #{$scr_sp} {
    width: auto;
  }
}
.color_twitter {
  color: #00aced;
}
.color_facebook {
  color: #305097;
}
.sp-only {
  display: none !important;
  @media #{$scr_sp} {
    display: block !important;
  }
}
.pc-only {
  display: block !important;
  @media #{$scr_sp} {
    display: none !important;
  }
}

// ロゴ
.logo-1{fill:#79b6e4;}
.logo-2{fill:#fff;}
.logo-3{fill:none;}
.logo-4{fill:#ed809c;}
.logo-5{fill:#444;}

.arrow {
  width: 5px;
  height: 8px;
}

.error-message {
  color: $color_sub;
}

/* ---------------------------------------------------------
header
----------------------------------------------------------*/
.header {
  background: #fff;
  padding: 24px 0 27px;
  @media #{$scr_sp} {
    padding: 10px 0;
  }
}
.scroll_header {
  display: none;
  @media #{$scr_sp} {
    display: flex;
    position: fixed;
    left: 0;
    top:0;
    width: 100%;
    padding: 10px;
    background: #fff;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 6000;
    box-sizing:border-box;
  }
  ul {
    display: flex;
    li {
      &:first-child {
        margin-right: 5px;
      }
      a {
        display: block;
        color: #fff;
        height: 40px;
        line-height: 40px;
        box-sizing:border-box;
        padding: 0 15px;
        @include font-size(1.2);
        text-decoration: none;
        svg {
          fill:#fff;
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
    }
    .scroll_header_login {
      a {
        background: $color_main;
      }
    }
    .scroll_header_logout {
      a {
        background: #cbc7be;
      }
    }
    .scroll_header_register {
      a {
        background: $color_sub;
      }
    }
  }
  .drawer-toggle {
    margin-left: auto;
    width: 40px;
    height: 40px;
    padding: 0;
    background: $color_main;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    .arrows_hamburger1 {
      fill:#fff;
      vertical-align: -2px;
    }
  }
}
.header_top {
  @include clearfix;
}
.header_logo {
  float: left;
  margin: 0;
  @media #{$scr_sp} {
    float: none;
    text-align: center;
  }
  .logo {
    width: 159px;
    height: 64px;
    @media #{$scr_sp} {
      transform:scale(0.7);
    }
  }
}
.header_right {
  float: right;
  display: flex;
  justify-content:flex-end;
  @media #{$scr_sp} {
    display: none;
  }
  a {
    color:$color_text;
    text-decoration: none;
  }
  .default-link {
    @include font-size(1.0);
    display: flex;
    justify-content:flex-end;
    margin-bottom: 9px;
    li {
      border-left:1px solid #b1b1b1;
      padding: 0 10px;
      &:first-child {
        border: none;
      }
      &:last-child {
        padding-right: 0;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .member-link {
    @include font-size(1.2);
    display: flex;
    justify-content:flex-end;
    li {
      margin-left: 5px;
    }
    a {
      display: block;
      background: $color_bg;
      padding: 3px 6px;
      &:hover {
        text-decoration: underline;
      }
    }
    .login {
      width: 8px;
      height: 9px;
    }
    .plus {
      width: 9px;
      height: 9px;
    }
  }
  .font-size-change {
    display: flex;
    li {
      text-align: center;
      padding: 0 3px;
      background: #efeee8;
      margin-left: 3px;
      cursor: pointer;
      border:none;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        
      }
      &:hover , &.current {
        color: #fff;
        background: #78c0f9;
      }
    }
  }
}
.font-size-change_drawer{
  display: flex;
  justify-content: center;  
  margin-bottom: 30px;
  li {
    text-align: center;
    padding: 0 3px;
    background: #efeee8;
    margin-left: 10px;
    cursor: pointer;
    border:none;
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      
    }
    &:hover , &.current {
      color: #fff;
      background: #78c0f9;
    }
  }
}
.header_menu {
  .drawer-toggle {
    position: fixed;
    right: 0;
    top:0;
    display: block;
    width: 50px;
    height: 50px;
    padding: 0;
    background: $color_main;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    transition: all .3s ease;
    z-index: 5000;
    @media #{$scr_sp} {
      position: absolute;
      right: 10px;
      top:23px;
      transform:scale(0.8);
    }
    &:hover {
      opacity: 0.7;
    }
    &.open {
      right: 360px;
      @media #{$scr_sp} {
        right: 10px;
      }
    }
    svg {
      fill:#fff;
      vertical-align: middle;
    }
    .hamburger {
      display: block;
      width: 30px;
      height: 30px;
      margin: 10px auto 0;
    }
    .close {
      display: none;
      width: 20px;
      height: 20px;
      margin: 15px auto 0;
    }
  }
}
.drawer-nav {
  position: fixed;
  top:0;
  right: -100%;
  bottom:0;
  width: 360px;
  background: #fff;
  box-sizing:border-box;
  overflow-y: scroll;
  z-index: 7000;
  transition: all .3s ease;
  @media #{$scr_sp} {
    width: 90%;
  }
  a {
    color: $color_text;
    text-decoration: none;
    &:hover {
      color:$color_main;
      & > svg {
        fill:$color_main;
      }
    }
  }
  .btn {
    &:hover {
      text-decoration: underline;
      svg {
        fill:#fff;
      }
    }
  }
  .menu_logo {
    text-align: center;
    margin-bottom: 30px;
    .logo {
      width: 195px;
      height: 79px;
    }
  }
  .list_login {
    margin-bottom: 30px;
    li {
      margin-bottom: 10px;
    }
    a {
      display: block;
      color: #fff;
      &.btn_member {
        background: #fe678c;
      }
      &.btn_logout {
        background: #cbc7be;
      }
      svg {
        margin-right: 10px;
      }
    }
  }
  .menu_main {
    margin: 0 -30px;
    border-top:1px solid $color_border;
    & > li {
      border-bottom:1px solid $color_border;
      font-weight: bold;
    }
    a {
      display: block;
      padding: 20px 30px;
      svg {
        margin-right: 10px;
      }
    }
    .togggle_btn {
      position: relative;
      &.active {
        border-bottom:1px solid $color_border;
        margin-bottom: 20px;
        .arrow {
          transform:rotate(90deg);
        }
      }
      .arrow {
        position: absolute;
        right: 30px;
        top:28px;
        margin: 0;
      }
    }
  }
  .menu_inner {
    margin-bottom: 20px;
    a {
      padding: 0 30px;
      margin-bottom: 10px;
      font-weight: normal;
      // @include font-size(1.4);
      &:hover {
        &.hane {
          color:#3ed7ea;
        }
        &.parrot {
          color:#4dbaee;
        }
        &.fork {
          color:#45a8ff;
        }
        &.hospital {
          color:#1c98f9;
        }
        &.hart {
          color:#c985ff;
        }
        &.cracker {
          color:#f282ff;
        }
        &.stump {
          color:#fe678c;
        }
        &.shop {
          color:#ff9dc0;
        }
        &.nest {
          color:#ff6d33;
        }
        &.water {
          color:#ff961b;
        }
        &.binoculars {
          color:#f6d915;
        }
        &.chick {
          color:#a2ed00;
        }
        &.love {
          color:#33ea74;
        }
        &.kago {
          color:#2dead2;
        }
      }
    }
    svg {
      // width: 14px;
      // height: 14px;
    }
    .hane {
      fill:#3ed7ea;
    }
    .parrot {
      fill:#4dbaee;
    }
    .fork {
      fill:#45a8ff;
    }
    .hospital {
      fill:#1c98f9;
    }
    .hart {
      fill:#c985ff;
    }
    .cracker {
      fill:#f282ff;
    }
    .stump {
      fill:#fe678c;
    }
    .shop {
      fill:#ff9dc0;
    }
    .nest {
      fill:#ff6d33;
    }
    .water {
      fill:#ff961b;
    }
    .binoculars {
      fill:#f6d915;
    }
    .chick {
      fill:#a2ed00;
    }
    .love {
      fill:#33ea74;
    }
    .kago {
      fill:#2dead2;
    }
  }
  .menu_sub {
    @include font-size(1.4);
    margin-top: 30px;
    margin-bottom: 30px;
    li {
      margin-bottom: 10px;
      a {
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  .menu_sns {
    @include font-size(1.4);
    li {
      margin-bottom: 20px;
    }
    a {
      display: block;
      // text-align: center;
      background: $color_facebook;
      color: #fff;
      // padding: 15px 10px;
      &.facebook {

      }
      &.twitter {
        background: $color_twitter;
      }
      svg {
        fill:#fff;
        margin-left: 10px;
      }
    }
  }
}
.drawer-menu {
  height: 100%;
  padding: 30px;
  .menu_logo {
    img {
      width: 195px;
    }
  }
}
.js_layer {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: 0 0;
  z-index: 85;
  transition: all .3s ease;
  background-color: rgba(0,0,0,.2);
}
body.open {
  .hamburger {
    display: none !important;
  }
  .close {
    display: block !important;
  }
  .drawer-nav {
    right: 0;
  }
  .js_layer {
    display: block;
  }
  .scroll_header {
    //left: -90%;
    //box-shadow: none;
    z-index: 80;
  }
  .header_gnav {
    z-index: 80;
  }
}
.header_gnav {
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  @media #{$scr_sp} {
    position: fixed;
    left: 0;
    bottom:0;
    width: 100%;
    z-index: 5000;
  }
  ul {
    display: flex;
    align-items:flex-end;
    justify-content:space-between;
    @media #{$scr_sp} {
      align-items:center;
      //min-height: 50px;
      box-sizing:border-box;
      //min-height: 60px;
      border-top: 1px solid #bfbfbf;
      background: #fff;
    }
  }
  li {
    border-left: 1px solid #bfbfbf;
    @media #{$scr_sp} {
      flex: 1;
      //min-height: 54px;
      box-sizing:border-box;
    }
    &:first-child {
      @media #{$scr_sp} {
        border-left: none;
      }
    }
    &:last-child {
      border-right: 1px solid #bfbfbf;
      @media #{$scr_sp} {
        border-right: none;
      }
    }
    &.active {
      a {
        .eng , .jp {
          color: $color_main;
        }
        svg {
          fill:$color_main;;
        }
      }
    }
  }
  a {
    width: 170px;
    display: block;
    text-decoration: none;
    color: $color_text;
    @media #{$scr_sp} {
      width: auto;
      height: 100%;
      //min-height: 60px;
      padding: 5px;
      padding-top: 10px;
      padding-bottom: 15px;
      box-sizing:border-box;
    }
    &:hover {
      .eng , .jp {
        color: $color_main;
      }
      svg {
        fill:$color_main;;
      }
    }
  }
  .jp {
    margin-top: 5px;
    @include font-size(1.4);
    @media #{$scr_sp} {
      @include font-size(0.6);
      //transform:scale(0.8);
      //margin-top: 0;
    }
  }
  .eng {
    @include font-size(1.0);
    color: $color_text-light;
    font-weight: normal;
    @media #{$scr_sp} {
      display: none;
    }
  }
  .kago {
    width: 13px;
    height: 24px;
  }
  .basic_world {
    width: 24px;
    height: 24px;
  }
  .basic_spread_text_bookmark {
    width: 24px;
    height: 20px;
  }
  .basic_notebook {
    width: 17px;
    height: 24px;
  }
  .basic_geolocalize-05 {
    width: 24px;
    height: 20px;
  }
  .coffee {
    width: 125px;
    height: 24px;
  }
  .software_pen {
    width: 24px;
    height: 24px;
  }
  svg {
    @media #{$scr_sp} {
      width: 15px !important;
      height: 15px !important;
    }
  }
  .community_parent {
    position: relative;
    &:hover {
      cursor: pointer;
      .community_subnavi {
        top: 100%;
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .community_subnavi {
    position: absolute;
    left: -14px;
    width: 200px;
    display: inherit;
    z-index: 9999;
    background: #fff;
    border-top: 1px solid #eee;
    // display: none;
    opacity: 0;
    top: 50%;
    visibility: hidden;
    transition: .5s;
    li {
      font-size: 12px;
      border: none;
      padding: 10px 8px;
      border-bottom: 1px solid #eee;
      a {
        margin: 0 auto;
        cursor: pointer;
        &:hover {
          color: #78c0f9;
          transition: 0s;
        }
      }
      &:last-child {
        // border-bottom: none;
      }
    }
  }
}


#flashMessage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  background-color: rgba($color_sub, 0.8);
  width: 100%;
  color: #fff;
  z-index: 9999;
}

#flashMessage.error {
  background-color: rgba($color_sub, 0.8);
}


/* ---------------------------------------------------------
content
----------------------------------------------------------*/
.content {
  padding: 20px 0 0 0;
  @media #{$scr_sp} {
    //padding: 5%;
  }
}

/* ---------------------------------------------------------
main
----------------------------------------------------------*/
.main {
  float: left;
  width: 860px;
  @media #{$scr_sp} {
    width: auto;
    float: none;
    margin: 0 5%;
  }
  .adsbygoogle {
    margin-bottom: 30px;
  }
}

/* ---------------------------------------------------------
side
----------------------------------------------------------*/
.side {
  float: right;
  width: 300px;
  @media #{$scr_sp} {
    width: auto;
    float: none;
    margin-bottom: 80px;
  }
}
.side_ad {
  margin-bottom: 20px;
  @media #{$scr_sp} {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 0;
  }
}
.side_login {
  background: #fff url("../img/common/pattern.jpg") repeat-x;
  background-size: 150px 5px;
  margin-bottom: 20px;
  padding: 25px 20px 20px 20px;
  @media #{$scr_sp} {
    margin-left: 5%;
    margin-right: 5%;
    display: none;
  }
  .title_login {
    text-align: center;
    color: $color_main;
    margin: 0 0 30px 0;
    span {
      display: block;
    }
    .jp {
      @include font-size(1.8);
    }
    .eng {
      @include font-size(1.2);
      font-weight: normal;
    }
  }
  .hidden {
    display: none;
  }
  fieldset {
    margin: 0;
    border: none;
    padding: 0;
  }
  .list_login {
    @include font-size(1.4);
    li {
      margin-bottom: 10px;
    }
    input[type="text"] , input[type="password"]  {
      width: 100%;
      box-sizing:border-box;
    }
  }
  .btn_submit {
    width: 100%;
    text-align: center;
    border: none;
    background: $color_main;
    color: #fff;
    box-sizing:border-box;
    @include font-size(1.4);
    margin-bottom: 15px;
    padding: 10px 0;
    border-radius: 0;
    .arrow {
      fill:#fff;
      margin-right: 5px;
    }
  }
  .list_memory {
    @include font-size(1.2);
    margin-bottom: 20px;
    a {
      // color: $color_sub;
      color: #fe678c;
    }
  }
  .btn_facebook {
    display: inline-block;
    width: 100%;
    text-align: center;
    background: #3c5a99;
    color: #fff;
    padding: 10px 0;
    @include font-size(1.6);
    text-decoration: none;
    .facebookIcon {
      width: 24px;
      height: 24px;
      fill:#fff;
      margin-right: 10px;
    }
  }
  .btn_register {
    display: block;
    margin-top: 40px;
    background: #fe668c;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    padding: 10px 30px;
    border-box: box-sizing;
    text-align: center;
    position: relative;
    p:first-child {
      background: #fff;
      width: 75%;
      border-box: box-sizing;
      padding: 3px 0;
      border-radius: 10px;
      margin: 0 auto 10px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border: 5px solid transparent;
        border-top: 5px solid #fff;
      }
    }
    span {
      display: inline-block;
      color: #fe668c;
      font-size: 10px;
      .emphasis {
        -webkit-text-emphasis: filled circle #fe668c;
        text-emphasis: filled circle #fe668c;
      }
    }
    p:last-child {
      border-bottom: 1px dashed #fff;
      position: relative;
      &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../svg/kazari_sen.svg") no-repeat;
        position: absolute;
        bottom: 15px;
        left: -10px;
      }
    }
    &:after {
      content: "";
      display: inline-block;
      width:  36.5px;
      height: 36.5px;
      background: url("../svg/kazari_arrow.svg") no-repeat;
      position: absolute;
      bottom: 0px;
      right: 0px;
    }
  }
}
.side_mypage {
  @include font-size(1.4);
  .list_btn {
    margin-top: 20px;
    li {
      margin-bottom: 10px;
    }
    a {
      width: 100%;
      padding: 10px 0;
      box-sizing:border-box;
      @include font-size(1.4);
    }
    .btn_logout {
      background: #cbc7be;
    }
  }
}
.side_bnr {
  @media #{$scr_sp} {
    margin-bottom: 80px !important;
  }
  .sp-slide {
    @media #{$scr_pc} {
      position: static;
    }
  }
  li {
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
}

/* ---------------------------------------------------------
sub
----------------------------------------------------------*/
.sub {
  margin-top: 85px;
  @media #{$scr_sp} {
    margin-top: 0;
  }
  .sub_block {
    background: #fff url("../img/common/pattern.jpg") repeat-x;
    background-size: 150px 5px;
    padding: 85px 0 80px;
    &:nth-child(even){
      background: $color_bg url("../img/common/pattern.jpg") repeat-x;
      background-size: 150px 5px;
    }
    &.cafe {
      background: #fff url("../img/common/pattern.jpg") repeat-x;
      background-size: 150px 5px;
    }
    &.news {
      padding-bottom: 0;
      background: $color_bg url("../img/common/pattern.jpg") repeat-x;
      background-size: 150px 5px;
    }
  }
  .sub_block_inner {
    @media #{$scr_sp} {
      margin: 0 5%;
    }
  }
  .title_sub {
    text-align: center;
    margin: 0 0 60px 0;
  }
  .boutou_sub {
    text-align: center;
    margin-top: -30px;
    margin-bottom: 60px;
    .newwindow {
      fill:$color_main;
      width: 14px;
      vertical-align: -3px;
      margin-left: 3px;
    }
  }
  .list_pickup {
    display: flex;
    flex-wrap:wrap;
    justify-content:space-between;
    align-content:space-between;
    @include font-size(1.6);
    .sp-slide {
      @media #{$scr_pc} {
        position: static;
      }
    }
    li {
      margin-bottom: 45px;
    }
    a {
      text-decoration: none;
    }
    img {
      margin-bottom: 5px;
      @media #{$scr_sp} {
        width: 100%;
      }
    }
    figcaption {
      color: $color_text;
    }
    h1 {
      margin: 0;
      @include font-size(1.6);
      font-weight: normal;
    }
  }
  .sub_ad {
    text-align: center;
    @media #{$scr_sp} {
      margin: 80px 5% 0;
    }
  }
  .cafe_inner {
    display: flex;
    justify-content:space-between;
    margin-bottom: 55px;
    @media #{$scr_sp} {
      display: block;
    }
  }
  .cafe_left {
    @media #{$scr_sp} {
      margin-bottom: 25px;
    }
    img {
      width: 100%;
    }
  }
  .cafe_right {
    width: 600px;
    @media #{$scr_sp} {
      width: auto;
    }
  }
  .nav_cafe {
    margin-bottom: 25px;
    ul {
      display: flex;
      flex-wrap:wrap;
      border-top: 1px solid $color_border;
      border-left: 1px solid $color_border;
      @media #{$scr_sp} {
        display: block;
      }
      li {
        width: 50%;
        @media #{$scr_sp} {
          width: 100%;
        }
        a {
          display: block;
          padding: 15px 20px;
          //text-align: center;
          text-decoration: none;
          color:$color_text;
          @include font-size(1.4);
          border-right: 1px solid $color_border;
          border-bottom: 1px solid $color_border;
          &:hover {
            background: #efeee8;
          }
          svg {
            margin-right: 10px;
          }
          .coffee {
            fill:#ff961b;
          }
          .eagle {
            fill:#f6d915;
          }
          .picture {
            fill:#c985ff;
          }
          .crown {
            fill:#c59f22;
          }
          .hart {
            fill:#fe678c;
          }
          .lineIcon2 {
            fill:#6aaf58;
          }
        }
      }
    }
  }
  .title_cafe_pickup {
    position: relative;
    @include font-size(2.0);
    border: 1px solid $color_border;
    border-bottom:none;
    margin:0;
    padding: 22px 20px 22px 40px;
    &:before {
      position: absolute;
      left: 22px;
      top:24px;
      content: "";
      width: 5px;
      height: 26px;
      background: $color_main;
    }
  }
  .list_cafe_pickup {
    border-top: 1px solid $color_border;
    border-left: 1px solid $color_border;
    li {
      border-right: 1px solid $color_border;
      border-bottom: 1px solid $color_border;
      a {
        position: relative;
        display: block;
        padding: 15px 20px;
        text-decoration: none;
        color:$color_text;
        &:hover {
          background: #f7f7f7;
        }
        article {
          display: flex;
          align-content:flex-start;
          @media #{$scr_sp} {
            display: block;
          }
        }
        time {
          margin-right: 15px;
          color: $color_text-light;
          @media #{$scr_sp} {
            @include font-size(1.4);
          }
        }
        h1 {
          @include font-size(1.6);
          font-weight: normal;
          margin: 0;
        }
        .new {
          position: absolute;
          left: -20px;
          top:-10px;
        }
      }
    }
  }
  .list_news {
    li {
      margin-bottom: 2px;
      &.no-link {
        position: relative;
        background: #fff;
        padding: 25px 30px;
      }
    }
    a {
      position: relative;
      display: block;
      background: #fff;
      padding: 25px 30px;
      color: $color_text;
      text-decoration: none;
      &.no-link {
        cursor: default;
      }
      &:hover {
        p {
          color: $color_main;
        }
      }
    }
    time {
      color: $color_text-light;
      @media #{$scr_sp} {
        @include font-size(1.4);
      }
    }
    .new {
      position: absolute;
      left: -10px;
      top:-10px;
    }
  }
}

/* ---------------------------------------------------------
footer
----------------------------------------------------------*/
.footer {
  margin-top: 80px;
  @media #{$scr_sp} {
    margin-top: 0;
  }
  .title_sub{
  text-align: center;
  }
  .boutou_sub{
  text-align: center;
  }
  .horizontal_scroll {
    width: 1200px;
    margin: 30px auto;
    @media #{$scr_sp} {
      width: 100%;
      margin-top: 50px;
    }
  }
  .sub_block_inner{
    margin-bottom: 50px;
    .btn_area{
      text-align: center;
    }
    a{
      text-align: center;
      margin: 0 auto;
    }
  }
}
.footer_ad {
  background: #fff;
  padding: 60px 0;
  text-align: center;
  @media #{$scr_sp} {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 30px;
    display: none;
  }
  p {
    background: $color_text;
    color:#fff;
    width: 970px;
    height: 90px;
    margin: 0 auto;
  }
}
.footer_link {
  background: $color_bg url("../img/common/bg_footer@2x.jpg") no-repeat;
  background-size: cover;
  padding: 70px 0;
  @include font-size(1.3);
  @media #{$scr_sp} {
    padding-right: 5%;
    padding-left: 5%;
  }
  a {
    text-decoration: none;
    color:$color_text;
    &:hover {
      color:$color_main;
      svg {
        fill:$color_main;
      }
    }
    svg {
      margin-right: 10px;
      &.arrow {
        margin-right: 5px;
      }
    }
  }
  .list_wrapper {
    display: flex;
    justify-content:space-between;
    @media #{$scr_sp} {
      display: block;
    }
  }
}
.footer_link_inner {
  display: flex;
  justify-content:space-between;
  @media #{$scr_sp} {
    display: block;
  }
}
.title_footer_link {
  @include font-size(1.3);
  margin: 0;
  margin-bottom: 10px;
}
.list_footer_link {
  li {
    margin-bottom: 10px;
  }
}
.footer_link_left {
  @media #{$scr_sp} {
    margin-bottom: 60px;
  }
  .list_footer_link {
    margin-right: 60px;
  }
}
.footer_link_right {
  .list_footer_link {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.copyright {
  background:$color_text;
  color: #fff;
  @include font-size(1.2);
  text-align: center;
  height: 50px;
  line-height: 50px;
  @media #{$scr_sp} {
    margin-bottom: 65px;
  }
}
.pagetop_wrapper {
  text-align: right;
   @media #{$scr_sp}{
    position: relative;
   }
}
.pagetop {
  position: fixed;
  right: 0;
  bottom:0;
  width: 50px;
  height: 50px;
  padding-top: 4px;
  text-align: center;
  background: $color_main;
  color: #fff;
  @include font-size(1.4);
  cursor: pointer;
  box-sizing:border-box;
  transition: all .5s ease ;
  &:hover {
    opacity: 0.7;
  }
  @media #{$scr_sp} {
    position: static;
    width: 100%;
  }
  &.sp-only {
    width: 50px;
    display: inline-block !important;
  }
  .arrow {
    transform:rotate(-90deg);
    fill:#fff;
    width: 12px;
    height: 12px;
  }
}
.list_sns {
  position: fixed;
  right: 0;
  top:50px;
  margin-top: 1px;
  z-index: 5000  ;
  @media #{$scr_sp} {
    display: none;
  }
  li {
    margin-bottom: 1px;
  }
  .twitter {
    a {
      &:hover {
        background: $color_twitter;
      }
    }
  }
  a {
    display: block;
    width: 50px;
    height: 50px;
    background: $color_text-light;
    text-align: center;
    line-height: 50px;
    &:hover {
      background: $color_facebook;
    }
    svg {
      fill:#fff;
    }
  }
}

/* ---------------------------------------------------------
見出し
----------------------------------------------------------*/
.title_lvl01 {
  margin:  0 0 60px 0;
  img {
    width: 100%;
  }
}
.title_lvl02 {
  position: relative;
  margin-bottom: 33px;
  padding: 18px 0;
  text-align: center;
  @include font-size(1.8);
  font-weight: normal;
  border-top:1px solid $color_text;
  border-bottom:1px dashed $color_text;
  &:after {
    position: absolute;
    left: 0;
    top:calc(100% + 3px);
    content: "";
    width: 100%;
    height: 1px;
    border-bottom:1px dashed $color_text;
  }
  .more {
    position: absolute;
    right: 0;
    top:50%;
    margin-top: -10px;
    @include font-size(1.4);
    color: $color_text;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    .arrow {
      margin-right: 5px;
    }
  }
}


/* ---------------------------------------------------------
パーツ
----------------------------------------------------------*/
.btn_wrapper {
  text-align: center;
}
.caption {
  @include font-size(1.4);
  //margin-bottom: 0 !important;
  text-indent: -1em;
  padding-left: 1em;
}
.btn {
  display: inline-block;
  background: $color_main;
  color: #fff;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 0;
  transition: all .5s ease ;
  &:hover {
    opacity: 0.7;
  }
  svg {
    fill:#fff;
  }
  .arrow {
    margin-right: 10px;
    vertical-align: 2px;
  }
  .newwindow {
    margin-left: 10px;
    width: 14px;
    height: 14px;
    vertical-align: -1px;
  }
}
.btn_facebook {
  background: #3c5a99;
  color: #fff;
  .facebookIcon {
    width: 24px;
    height: 24px;
    fill:#fff;
    margin-right: 10px;
  }
}
.box {
  background: #fff;
  padding: 20px;
  .bbs &,
  .topic &{
    padding: 15px;
    background: $color_main;
  }
}
.new {
  position: relative;
  width: 25px;
  height: auto;
  vertical-align: bottom;
}
.breadcrumb {
  @include font-size(1.4);
  margin: 10px 0 30px;
  @media #{$scr_sp} {
    margin: 0 5%;
  }
  ul {
    display: flex;
    flex-wrap:wrap;
    li {
      margin-right: 5px;
    }
    a {
      text-decoration: none;
      margin-right: 5px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.dl_default {
  display: flex;
  flex-wrap:wrap;
  @media #{$scr_sp} {
    display: block;
  }
  dt , dd {
    background: #fff;
    margin: 0 !important;
    padding: 25px;
    box-sizing:border-box;
    @media #{$scr_sp} {
      padding: 15px;
    }
  }
  dt {
    width: calc(20% - 2px);
    margin-right: 2px !important;
    margin-bottom: 2px !important;
    border-left: 2px solid $color_main;
    color: $color_main;
    @media #{$scr_sp} {
      width: 100%;
      margin-right: 0;
    }
  }
  dd {
    width: 80%;
    margin-bottom: 2px !important;
    @media #{$scr_sp} {
      width: 100%;
      @include font-size(1.4);
      border-left: 2px solid rgba($color_text-light,0.4);
    }
    p {
      margin-bottom: 1em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .map {
      margin-left: 20px;
    }
  }
}
.list_default {
  border-top: 1px dashed $color_text;
  li {
    position: relative;
    border-bottom: 1px dashed $color_text;
    padding: 15px 20px;
    margin: 0 !important;
    &:before {
      position: absolute;
      left: 0;
      top:23px;
      content: "";
      width: 10px;
      height: 10px;
      background:$color_main;
      border-radius: 50%;
    }
  }
}
.list_topic {
  li {
    margin-bottom: 2px;
  }
  a {
    position: relative;
    display: block;
    background: #fff;
    padding: 9px 10px;
    text-decoration: none;
    box-sizing: border-box;
    @media #{$scr_sp}{
      padding: 5px 15px;
    }
    &:hover {
      text-decoration: none !important;
      .title {
        color:$color_main;
        span {
          // color:$color_text;
        }
      }
    }
    span.name{
      font-size: 1.2rem;
      margin-left: 10px;
    }
    article{
      .bbs &{
        line-height: normal;
      }
    }
  }
  time {
    color: $color_text-light;
    @include font-size(1.2);
  }
  .saishin {
    color:$color_sub;
  }
  .icon_category {
    display: inline-block;
    margin:0 !important;
    color: #929292;
    @include font-size(1.2);
    vertical-align: middle;
    &.zatsudan {background: #3ed7ea;}
    &.kaikata {background: #4dbaee;}
    &.kenkou {background: #45a8ff;}
    &.byouki {background: #1c98f9;}
    &.jiman {background: #c985ff;}
    &.off {background: #f282ff;}
    &.goods {background: #fe678c;}
    &.hanbai {background: #fe4f4f;}
    &.raise {background: #ff6d33;}
    &.loss {background: #ff961b;}
    &.watching {background: #f6d915;}
    &.maigo {background: #a2ed00;}
    &.hogo {background: #33ea74;}
    &.satooya {background: #2dead2;}
  }
  .title {
    margin: 0;
    @include font-size(1.4);
    color: $color_text;
    font-weight: bold;
    margin-top: 3px;
    @media #{$scr_sp}{
      @include font-size(1.6);
      margin-bottom: 3px;
    }
  }
  span.total_num {
    color: #929292;
    font-size: 12px;
    position: relative;
    margin-right: 10px;
    &:before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 17px;
      background: url(/assets/img/topics/icon_reaction_gray.svg) no-repeat;
      vertical-align: middle;
    }
  }
  .reply_span{
    color: #929292;
    font-size: 12px;
    position: relative;
    padding-left: 15px;
    margin-right: 5px;
    &:before{
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background: url(/assets/img/common/reply_icon.svg) no-repeat;
      width: 13px;
      height: 12px;
      display: inline-block;  
    }
  }
}
.list_board {
  li {
    margin-bottom: 2px;
    background: #fff;
    padding: 15px;
    time {
      color: #aaaaaa;
      font-size: 14px;
      font-size: 1.4rem;
      margin-right: 15px;
    }
    p.name {
      margin: 0;
    }
    p.name a.del_board {
      float: right;
      min-width: 0 !important;
      padding: 0 15px;
      background: brown;
    }
    p.message {
      margin: 0;
    }
  }
}
.list_member {
  display: flex;
  flex-wrap:wrap;
  justify-content:flex-start;
  li {
    width: calc(20% - 10px);
    margin-right: 10px;
    margin-bottom: 10px;
    background: #fff;
    @media #{$scr_sp} {
      width: calc(50% - 5px);
    }
    &:nth-child(even) {
      @media #{$scr_sp} {
        margin-right: 0;
      }
    }
    &:nth-child(5n+5) {
      @media #{$scr_pc} {
        margin-right: 0;
      }
    }
    a {
      display: block;
      background: #fff;
      padding-bottom: 10px;
      &:hover {
        text-decoration: none !important;
      }
    }
    figure {
      max-height: 162px;
      overflow: hidden;
      margin-bottom: 10px;
    }
    img {
      width: 100%;
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 0 !important;
      margin-left: 10px;
      margin-right: 10px;
    }
    .name {
      @include font-size(1.4);
    }
    .caption {
      @include font-size(1.2);
      color: $color_text;
      line-height: 1.5;
    }
  }
}
.list_zukan {
  @include font-size(1.4);
  display: flex;
  flex-wrap: wrap;
  @media #{$scr_sp} {
    display: block;
  }
  li {
    width: calc(25% - 8px);
    margin-right: 10px;
    margin-bottom: 10px;
    background: #fff;
    @media #{$scr_sp} {
      width: 100%;
      margin-right: 0;
    }
    &:nth-child(4n+4) {
      margin-right: 0;
    }
    a {
      display: block;
      background: #fff;
      padding: 10px 20px;
    }
  }
}
.list_blog {
  display: flex;
  flex-wrap:wrap;
  justify-content:space-between;
  align-content:space-between;
  margin-bottom: 40px;
  li {
    width: 392px;
    background: #fff;
    @include clearfix;
    margin-bottom: 15px;
    padding: 15px;
    box-sizing:border-box;
    a {
      color: $color_text;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .img {
      float: left;
      margin-right: 15px;
    }
    .text {
      padding-left: 99px + 15px
    }
    time {
      display: inline-block;
      @include font-size(1.4);
      margin-right: 10px;
      margin-bottom: 5px;
      color: $color_text-light;
    }
    .title {
      display: block;
      margin-bottom: 5px;
      //color: $color_main;
    }
    .name {
      @include font-size(1.4);
      color: $color_main;
    }
  }
}
.table_caption {
  text-align: center;
  margin-bottom: 1em !important;
  margin-top: 1em;
  @include font-size(1.2);
}
.table_wrapper {
  width: 100%;
  overflow: scroll;
}
.table_default {
  border-collapse: separate;
  border-spacing: 2px;
  width: 860px;
  margin-bottom: 2em;
  @include font-size(1.4);
  @media #{$scr_sp} {
    width: auto;
    @include font-size(1.2);
  }
  th {
    background: $color_main;
    color: #fff;
    padding: 10px 20px;
    @media #{$scr_sp} {
      padding: 10px;
    }
  }
  td {
    background: #fff;
    padding: 15px 20px;
    text-align: left;
    @media #{$scr_sp} {
      padding: 10px;
    }
  }
  .col01 {
    // background: rgba( $color_main, 0.2 );
    // background: lighten( $color_main, 20% );
    background: $color_table;
    color: $color_main;
  }
}
.pageng {
  background: #fff;
  padding: 20px 20px 30px;
  display: block;
  position: relative;
  @include font-size(1.4);
  @media #{$scr_sp} {
    display: block;
    position: relative;
    padding: 30px 20px 15px;
  }
  & > p {
    text-align: center;
    @media #{$scr_sp} {
      text-align: center;
    }
  }
  p , ul , li {
    margin: 0 !important;
  }
  .prevLink , .nextLink{
    position: absolute;
    @media #{$scr_pc} {
      display: block;
      position: absolute;
    }
  }
  .prevLink {
    left: 20px;
    top:45px;
    @media #{$scr_sp}{
      top: 10px;
    }
  }
  .nextLink {
    right: 20px;
    top:45px;
    @media #{$scr_sp}{
      top: 10px;
    }
  }
}
.pagination {
  margin-bottom: 0 !important;
  margin-top: 5px;
  ul {
    display: flex;
    justify-content:center;
    @media #{$scr_sp} {
      justify-content:center;
      flex-wrap:wrap;
    }
    li {
      margin-left: 10px !important;
      &.current {
        a {
          color:$color_text;
        }
      }
    }
  }
}
.box_search {
  margin-bottom: 30px;
  @media #{$scr_sp} {
    //overflow: hidden;
  }
  form {
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$scr_sp} {
      display: block;
    }
    .type , .keyword{
      margin-right: 10px;
      @media #{$scr_sp} {
        margin-right: 0;
      }
    }
    input[type="text"] {
      height: 40px;
      width: 380px;
      background: $color_bg;
      border:1px solid $color_border;
      margin-right: 10px;
      @media #{$scr_sp} {
        width: 100%;
        height: 45px;
        margin-bottom: 10px;
        margin-right: 0;
        box-sizing:border-box;
      }
    }
    select {
      height: 44px;
      width: 320px;
      background: $color_bg;
      border:1px solid $color_border;
      box-sizing:border-box;
      border-radius: 0;
      @media #{$scr_pc} {
        margin-right: 10px;
      }
      @media #{$scr_sp} {
        width: 100%;
        height: 45px;
        margin-bottom: 10px;
      }
    }
    .btn {
      border: none;
      padding: 7px 19px;
      @include font-size(1.4);
      height: 34px;
      box-sizing:border-box;
      margin-left: auto;
      @media #{$scr_sp} {
        display: block;
        margin: 0 auto;
      }
      .topic &{
        height: 43px;
      }
    }
    div {
      margin-bottom: 5px !important;
      .bbs &{
        margin-bottom: 0 !important;
        &.submit{
          margin-left: 10px;
        }
      }
    }
  }
}
.hissu {
  margin-left: 10px;
  padding: 3px 5px;
  background: $color_sub;
  color: #fff;
  @include font-size(1.2);
}
.subnav_tab_wrapper {
  @media #{$scr_sp} {
    // width: 100%;
    // overflow-x:scroll;
    // margin-bottom: 0;
  }
}
.subnav_tab {
  display: flex;
  @include font-size(1.3);
  text-align: center;
  border-bottom: 2px solid $color_main;
  @media #{$scr_sp} {
    flex-wrap:wrap;
    @include font-size(1.2);
    //width: 460px;
    margin-bottom: 0;
  }
  li {
    flex:1;
    margin-right: 2px;
    height: 50px;
    line-height: 50px;
    @media #{$scr_sp} {
      flex:auto;
    }
    &:last-child {
      margin-right: 0;
    }
    &.active {
      background: $color_main;
      a {
        background: $color_main;
        color: #fff;
      }
    }
    a {
      display: block;
      background: #fff;
      height: 48px;
      padding:0 5px;
      //border-top: 2px solid $color_main;
      @media #{$scr_sp} {

      }
    }
  }
}
.unker_menu {
  ul {
    display: flex;
    justify-content:center;
    @media #{$scr_sp} {
      display: block;
      text-align: center;
      @include font-size(1.4);
    }
    li {
      margin-right: 30px;
      @media #{$scr_sp} {
        margin-right: 0;
      }
      &.active {
        a {
          color:$color_text;
        }
      }
    }
  }
}
.submenu {
  ul {
    display: flex;
    justify-content:space-between;
    li {
      width: 31%;
      text-align: center;
      &.active {
        a {
          background: $color_main;
          color:#fff;
        }
      }
      a {
        display: block;
        background: #FFF;
        color:$color_main;
        width: 100%;
        padding: 10px;
        box-sizing:border-box;
      }
    }
  }
}
.my_companion {
  .box {

  }
  .inner {
    display: flex;
  }
  .block_left {
    width:260px;
    margin-right: 30px;
    box-sizing:border-box;
    @media #{$scr_sp} {
      width: 100px;
      margin-right: 20px;
    }
  }
  .img {
    margin-bottom: 10px;
    overflow: hidden;
    figcaption {
      @include font-size(1.2);
      margin-top: 5px;
      line-height: 1.5;
    }
    img {
      width:100%;
    }
  }
  .list_default {
    width: 100%;
    li {
      padding-left: 0;
      &:before {
        display: none;
      }
    }
  }
  .btn {
    width: 100%;
    box-sizing:border-box;
    @include font-size(1.2);
  }
  .btn_edit {
    //margin-bottom: 10px;
  }
  .btn_delete {
    background: #cbc7be;
  }
  .title_lvl03 {
    background: $color_bg;
    margin-bottom: 30px;
  }
  .list_gallery {
    display: flex;
    margin-bottom: 20px;
    box-sizing:border-box;
    flex-wrap:wrap;
    li {
      width: 156px;
      max-height: 156px;
      margin-right: 10px;
      margin-bottom: 10px;
      overflow: hidden;
      @media #{$scr_pc} {
        &:nth-child(5n+5) {
          margin-right: 0;
        }
      }
      @media #{$scr_sp} {
        width: calc(50% - 5px);
        max-height: none;
        &:nth-child(even) {
          margin-right: 0;
        }
      }
      div {
        position: relative;
        p {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          margin: 0;
          line-height: 1;
           @media #{$scr_sp} {
            position: static;
           }
          a {
            display: none;
            background: rgba(0,0,0,0.8);
            color: #fff;
            font-size: 0.9em;
            padding: 5px;
            @media #{$scr_sp} {
              display: block;
              background: none;
              color: $color_text;
              @include font-size(1.2);
            }
          }
        }
        &:hover p a {
          display: block;
        }
      }
    }
    img {
      width: 100%;
      @media #{$scr_sp} {
        margin-bottom: 5px;
      }
    }
  }
  .block_right {
    width: 600px;
    text-align: left;
    @media #{$scr_sp} {
      width: 100%;
    }
  }
}
.bnr_top {
  margin-bottom: 1.5em;
}
/* ---------------------------------------------------------
下層ページ
----------------------------------------------------------*/
.lower {
  .main {
    p , div , dl , ul {
      margin-bottom: 2em;
      line-height: 1.8;
      word-wrap: break-word;
    }
    input,select,svg,figure,a {
      //margin-bottom: 0;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &[target="_blank"] {
        padding-right: 1em;
        background-image: url("../svg/icons/newwindow.svg");
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 12px;
      }
      &[href$=".pdf"] {
        background: none;
        &:after {
          content: "\f016";
          font-family: FontAwesome;
          @include font-size(1.2);
          vertical-align: 1px;
        }
      }
    }
    // .strong {
    //   color: $color_sub;
    // }
  }
  .side {
    @media #{$scr_sp} {
      margin-top: 50px;
      margin-bottom: 0;
    }
  }
  .title_lvl01 {
    position: relative;
    padding: 15px 0 17px;
    @include font-size(2.4);
    border-top:1px solid $color_text;
    border-bottom:1px solid $color_text;
    @media #{$scr_sp} {
      margin-top: 1.5em;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 100%;
      height: 4px;
      background: url("../img/common/title_bg.png")
    }
    svg {
      width: 20px;
      height: 20px;
      margin: 0;
      vertical-align: -1px;
    }
  }
  .title_lvl02 {
    text-align: left;
    margin-top: 80px;
    padding: 15px 0;
    padding-left: 20px;
    font-weight: bold;
    &:before {
      content: "";
      position: absolute;
      left: 0px;
      top:20px;
      width: 5px;
      height: calc(100% - 40px);
      background: $color_text;
    }
    span {
      @include font-size(1.4);
      font-weight: normal;
      display: block;
      margin-top: 10px;
    }
  }
  .title_lvl03 {
    position: relative;
     @include font-size(1.6);
     background: #fff;
     margin-top: 40px;
     margin-bottom: 2em;
     padding: 15px 20px;
     color: $color_main;
     border-left: 4px solid $color_main;
     span {
      // position: absolute;
      // right: 20px;
      // top:15px;
      margin-left: 10px;
      @include font-size(1.4);
      font-weight: normal;
     }
  }
  .topic_ichiran_h1{
    margin-bottom: 30px;
    @media #{$scr_sp}{
      font-size: 2rem;
    }
  }
  .title_blue {
    position: relative;
    margin-bottom: 2em;
    padding: 15px 20px;
    background: $color_main;
    color: #fff;
    @include font-size(1.6);
    span , a {
      position: absolute;
      right: 20px;
      top:18px;
      @include font-size(1.2);
      color: #fff;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .horizontal_scroll {
    @media #{$scr_sp} {
      margin-bottom: 20px;
    }
  }
}
.btn_wrapper{
    padding: 3px;
  }
  .btn_wrapper_border{
    border: solid 1px #fff;
    margin-bottom: 0 !important;
    padding: 27px;
    box-sizing: border-box;
    @media #{$scr_sp}{
      padding: 27px 10px;
    }
  }
  .text {
        display: inline-block;
        margin-bottom: 15px !important;
        position: relative;
        color: #fff;
        font-size: 1.8rem;
        font-weight: bold;
        @media #{$scr_sp}{
          font-size: 1.6rem;
          margin-left: 20px;
        }
        &:before{
          content: '';
          display: inline-block;
          background: url(/assets/img/common/reply_icon_white.svg) no-repeat;
          width: 22px;
          height: 20px;
          top: 50%;
          transform: translateY(-50%);
          background-size: contain;
          position: absolute;
          left: -25px;
          @media #{$scr_sp}{
            widows: 17px;
            height: 16px;
            left: -20px;
          }
        }
      }
      .btn_wrapper_bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0 !important;
        @media #{$scr_sp}{
          display: block;
        }
      }
      .touroku,
      .login_btn {
        margin-bottom: 0 !important;
        .btn {
          color: $color_main;
          background-color: #fff;
          outline: 1px solid #fff;
          outline-offset: -4px;
          @include font-size(1.4);
          padding: 10px 19px;
          @media #{$scr_sp}{
            width: 90%;
            padding: 12px 19px;
            box-sizing: border-box;
          }
          svg{
            fill:$color_main;
          }
        }

      }
      .touroku{
        @media #{$scr_sp}{
          width: 100%;
          margin-right: 0;
          display: inline-block;
        }
      }
      .login_btn{
        margin-right: 20px;
        position: relative;
        transition: all .5s ease;
        @media #{$scr_sp}{
          margin-right: 0;
          margin-bottom: 15px !important;
        }
        &:hover{
            opacity: 0.7;
            svg{
              fill:$color_main;
            }
          }
        svg{
          top: 50%;
          transform: translateY(-50%);
          left: 20px;
          position: absolute;
          fill:$color_main;
          @media #{$scr_sp}{
            left: 50px;
          }
          }
        a{
          padding: 10px 30px;
          box-sizing: border-box;
          color: $color_main;
          background-color: #fff;
          @include font-size(1.4);
          outline: 1px solid #fff;
          outline-offset: -4px;
          display: inline-block;
          svg{
            fill:$color_main;
          }
          @media #{$scr_sp}{
            display: inline-block;
            width: 90%;
            padding: 13px 30px;
          }
        }
      }
